import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {OAuthErrorEvent, OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {ApiService} from '../services/api.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import { StateService } from '../services/state.service';

export interface OauthProfile {
    profile?: any;
    stateUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

    private profileSubject$ = new BehaviorSubject<OauthProfile | undefined>(undefined);
    public profile$ = this.profileSubject$.asObservable();

    private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

    private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
    public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();


    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errorred, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
        this.isAuthenticated$,
        this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    private navigateToLoginPage() {
    // TODO: Remember current URL
    // this.router.navigateByUrl('/should-login');
        this.login();
    }

    constructor(
        private _oauthService: OAuthService,
        private _router: Router,
        private _apiService: ApiService,
        private _deviceDetector: DeviceDetectorService,
        private _stateService: StateService
    ) {

        this._oauthService.setStorage(localStorage);

        // Useful for debugging:
        this._oauthService.events.subscribe(event => {
            if (event instanceof OAuthErrorEvent) {
                console.error('OAuthErrorEvent Object:', event);
            } else {
                // console.warn('OAuthEvent Object:', event);
            }
        });

        // THe following cross-tab communication of fresh access tokens works usually in practice,
        // but if you need more robust handling the community has come up with ways to extend logic
        // in the library which may give you better mileage.
        //
        // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
        //
        // Until then we'll stick to this:
        window.addEventListener('storage', (event) => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
            this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());

            if (!this._oauthService.hasValidAccessToken()) {
                this.navigateToLoginPage();
            }
        });

        this._oauthService.events
            .subscribe(() => {
                this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());
            });
        this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());

        this._oauthService.events
            .pipe(filter(e => ['token_received'].includes(e.type)))
            .subscribe(f => {

                if(this._oauthService.hasValidAccessToken() && this.isDoneLoadingSubject$.getValue()) {
                    void this._oauthService.loadUserProfile().then((p: any) => {
                        this.profileSubject$.next({
                            profile: p?.info,
                            stateUrl: undefined
                        });
                    });
                }

            });


        let listenEvents = ['session_terminated', 'session_error'];

        console.log('_deviceDetector', _deviceDetector);

        if(_deviceDetector.browser === 'Safari' ||
          (_deviceDetector.browser === 'Chrome' && (_deviceDetector.isMobile() || _deviceDetector.isTablet()))) {
            listenEvents = ['session_terminated'];
        }

        this._oauthService.events
            .pipe(filter((e: OAuthEvent) => listenEvents.includes(e.type)))
            .subscribe((e: OAuthEvent) => this.navigateToLoginPage());

        this._oauthService.setupAutomaticSilentRefresh();

    }

    public runInitialLoginSequence(): Promise<void> {

        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
        }

        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return this._oauthService.loadDiscoveryDocumentAndTryLogin()

        // For demo purposes, we pretend the previous call was very slow
        // .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:


            .then(() => {

                if (this._oauthService.hasValidAccessToken()) {
                    return Promise.resolve();
                }

                // 2. SILENT LOGIN:
                // Try to log in via a refresh because then we can prevent
                // needing to redirect the user:
                return this._oauthService.silentRefresh()
                    .then(() => Promise.resolve())
                    .catch((result: any) => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the
                        // user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction = [
                            'interaction_required',
                            'login_required',
                            'account_selection_required',
                            'consent_required',
                        ];

                        if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the
                            // user to log in, so we redirect them to the IdServer to
                            // enter credentials.
                            //
                            // Enable this to ALWAYS force a user to login.
                            // this.login();
                            //
                            // Instead, we'll now do this:
                            console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
                            return Promise.resolve();
                        }

                        // We can't handle the truth, just pass on the problem to the
                        // next handler.
                        return Promise.reject(result);
                    });
            })
            .then(() => {

                // Check for the strings 'undefined' and 'null' just to be sure. Our current
                // login(...) should never have this, but in case someone ever calls
                // initImplicitFlow(undefined | null) this could happen.
                if (this._oauthService.state && this._oauthService.state !== 'undefined' && this._oauthService.state !== 'null') {
                    let stateUrl = this._oauthService.state;
                    if (stateUrl.startsWith('/') === false) {
                        stateUrl = decodeURIComponent(stateUrl);
                    }
                    console.log(`There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`);

                    return Promise.resolve(stateUrl);

                }

                console.log('isDoneLoadingSubject$');
                return Promise.resolve(undefined);

            })
            .then((stateUrl) => {

                this.isDoneLoadingSubject$.next(true);

                if (this._oauthService.hasValidAccessToken()) {

                    return this._oauthService.loadUserProfile().then((p: any) => {

                        // console.log("p", p);

                        /*
            if(stateUrl === '/home')
              stateUrl = '/collaboration-portal/work-list';

             */

                        this.profileSubject$.next({
                            profile: p?.info,
                            stateUrl
                        });

                        // console.log("stateUrl", stateUrl);

                        if(stateUrl) {
                            void this._router.navigateByUrl('/');
                        }


                    });

                }

            })
            .catch(() => {
                console.log('catch isDoneLoadingSubject$');

                this.isDoneLoadingSubject$.next(true);
            });
    }

    public login(targetUrl?: string): void {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.

        return this._oauthService.initCodeFlow(targetUrl || this._router.url);
    }

    public logout(): void {
        this._stateService.clearAllStates();
        this._oauthService.logOut();
    }
    public refresh(): void { void this._oauthService.silentRefresh(); }
    public hasValidToken(): boolean { return this._oauthService.hasValidAccessToken(); }

    // These normally won't be exposed from a service like this, but
    // for debugging it makes sense.
    /*
    public get accessToken() { return this._oauthService.getAccessToken(); }
    public get refreshToken() { return this._oauthService.getRefreshToken(); }
    public get identityClaims() { return this._oauthService.getIdentityClaims(); }
    public get idToken() { return this._oauthService.getIdToken(); }
    public get logoutUrl() { return this._oauthService.logoutUrl; }
    */

}
