import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject, takeUntil} from 'rxjs';
import {MatPaginatorIntl} from '@angular/material/paginator';

@Injectable()
export class TranslatedMatPaginator extends MatPaginatorIntl
    implements OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    OF_LABEL = 'of';

    constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange.pipe(
            takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this.translate
            .get([
                'Items per page',
                'Next page',
                'Previous page',
                'of',
                'First page',
                'Last page'
            ]).pipe(takeUntil(this.unsubscribe))
            .subscribe(translation => {
                this.itemsPerPageLabel = translation['Items per page'] || 'Items per page';
                this.nextPageLabel = translation['Next page'] || 'Next page';
                this.previousPageLabel = translation['Previous page'] || 'Previous page';
                this.firstPageLabel = translation['First page'] || 'First page';
                this.lastPageLabel = translation['Last page'] || 'Last page';
                this.OF_LABEL = translation.of || 'of';
                this.changes.next();
            });
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
      startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${
            this.OF_LABEL
        } ${length}`;
    };
}
