import {Directive, HostBinding, HostListener, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[cdkDetailRow]'
})
export class CdkDetailRowDirective {
    private row: any;
    private tRef?: TemplateRef<any>;

    @Input()
    set cdkDetailRow(value: any) {
        if (value !== this.row) {
            this.row = value;
            // this.render();
        }
    }

    @Input('cdkDetailRowTpl')
    set template(value: TemplateRef<any>) {
        if (value !== this.tRef) {
            this.tRef = value;
            // this.render();
        }
    }

    constructor(public vcRef: ViewContainerRef) { }

    @HostListener('click', ['$event.target'])
    onClick(clickTarget: Element): void {
        let doubleArrowClicked = false;
        const clickTargetParentElement: Element | null = clickTarget?.parentElement;
        if (clickTargetParentElement) {
            for (let i = 0; i < clickTargetParentElement.children.length; i++) {
                const childElement: Element = clickTargetParentElement.children[i];
                if (childElement.nodeName.toLowerCase() === 'mat-icon' && childElement.classList.contains('examination-arrow')) {
                    doubleArrowClicked = true;
                    break;
                }
            }
        }
        if (doubleArrowClicked) {
            this.toggle();
        }
    }

    toggle(): void {
        if (this.row.expanded) {
            this.render();
        } else {
            this.vcRef.clear();
        }
    }

    private render(): void {
        this.vcRef.clear();

        console.log('this.row', this.row);

        if (this.tRef && this.row ) {
            this.vcRef.createEmbeddedView(this.tRef, { $implicit: this.row });
        }
    }
}
