import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpHeaders,
    HttpRequest, HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, mergeMap, Observable, tap, throwError} from 'rxjs';
import {catchError, map, retry, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ApiResponse} from '../models/response.model';
import {OAuthService} from 'angular-oauth2-oidc';
import {v4 as uuidv4} from 'uuid';
import { ToastService } from './toast.service';
import {MultipartParseError, parseMultipart, parseMultipartRequest} from "@mjackson/multipart-parser";

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    // Http Headers
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };
    constructor(private _http: HttpClient, private _oauthService: OAuthService, private _toastService: ToastService) {}

    private isLoadingArrSubject$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    public isLoadingArr$: Observable<string[]> = this.isLoadingArrSubject$.asObservable();

    // REQUEST
    requestFormData<T>(path: string, formData: FormData): Observable<HttpEvent<ApiResponse<T>>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap(
            (header: { headers?: HttpHeaders}) => {

                let headers = header.headers;
                headers = header.headers?.delete('Content-Type');

                const request = new HttpRequest<FormData>(
                    'POST',
                    environment.apiBaseUrl + path,
                    formData,
                    {
                        reportProgress: true, // , responseType: 'text'
                        headers
                    }
                );

                return this._http
                    .request<ApiResponse<T>>(request)
                    .pipe(
                        tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                        retry(1),
                        catchError(err => this.errorHandlerHttpEvent<T>(err, this._toastService, jobId))
                    );
            }
        ));
    }


    // POST
    post<T>(path: string, data?: object): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap(
            (header: { headers?: HttpHeaders}) => this._http
                .post<ApiResponse<T>>(
                environment.apiBaseUrl + path,
                data ? JSON.stringify(data) : data,
                header
            )
                .pipe(
                    tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                    retry(1),
                    catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
                )
        ));
    }
    // GET
    get<T>(path: string): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap((header: { headers?: HttpHeaders}) =>

        // @ts-ignore
            this._http
                .get<ApiResponse<T>>(environment.apiBaseUrl + path, header)
                .pipe(
                    tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                    retry(1),
                    catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
                )

        ));
    }

    getWithCustomAccessToken<T>(path: string, accessToken: string): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        const header = this.SetAuthorizationHeader( accessToken );

        return this._http
            .get<ApiResponse<T>>(environment.apiBaseUrl + path, header)
            .pipe(
                tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                retry(1),
                catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
            );
    }

    // GET
    getMultiPart(path: string): Observable<File | string | undefined> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap((h: { headers?: HttpHeaders}) => {

            const option: { headers?: HttpHeaders, responseType: 'blob', observe: 'response'} = {
                headers: h.headers,
                responseType: 'blob',
                observe: 'response'
            };

            return this._http
                .get(environment.apiBaseUrl + path, option)
                .pipe(
                    switchMap( async res => {

                        //console.log('switchMap res', res);

                        if(!res.body)
                            return res;

                        const boundary = this.getBoundary(res.headers?.get('content-type'));

                        const multipartMessage = new Uint8Array(await res.body.arrayBuffer());

                        try {

                            await parseMultipart(multipartMessage, { boundary }, async (part) => {
                                console.log('part', part);

                                const bytes = await part.bytes();

                                const blob = new Blob([bytes], {type: part.mediaType});

                                const aFile = this.blobToFile(blob, part.filename ?? (uuidv4()+'.dcm'));

                                console.log("aFile", aFile);

                                res = res.clone({body: aFile});

                            });

                        } catch (error) {
                            if (error instanceof MultipartParseError) {
                                console.error('Failed to parse multipart request:', error.message);
                            } else {
                                console.error('An unexpected error occurred:', error);
                            }
                        }

                        return res;

                    }),
                    tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                    retry(1),
                    catchError(err => this.errorHandler<HttpResponse<ArrayBuffer>>(err, this._toastService, jobId)),
                    map( responseParsed => {

                        //console.log("responseParsed", responseParsed);

                        return responseParsed.body as File
                    }),
                )

            }

        ));
    }

    private getBoundary(header?: string | null): string {

        if(!header)
            return '';

        const items = header.split(';')
        if (items) {
            for (let i = 0; i < items.length; i++) {
                const item = new String(items[i]).trim()
                if (item.indexOf('boundary') >= 0) {
                    const k = item.split('=')
                    return new String(k[1]).trim().replace(/^["']|["']$/g, '')
                }
            }
        }
        return ''
    }


    public blobToFile = (theBlob: Blob, fileName:string): File => {
        return new File(
            [theBlob], // cast as any
            fileName,
            {
                lastModified: new Date().getTime(),
                type: theBlob.type
            }
        )
    }


    // PUT
    put<T>(path: string, data?: object): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap(
            (header: { headers?: HttpHeaders}) => this._http
                .put<ApiResponse<T>>(
                environment.apiBaseUrl + path,
                (data ? JSON.stringify(data) : data),
                header
            )
                .pipe(
                    tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                    retry(1),
                    catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
                )
        ));
    }

    putWithCustomAccessToken<T>(path: string, accessToken: string, data?: object ): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        const header = this.SetAuthorizationHeader( accessToken );

        return this._http
            .put<ApiResponse<T>>(
            environment.apiBaseUrl + path,
            (data ? JSON.stringify(data) : data),
            header)
            .pipe(
                tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                retry(1),
                catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
            );

    }
    // DELETE
    delete<T>(path: string): Observable<ApiResponse<T>> {

        const jobId = uuidv4();
        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().concat([jobId]) );

        return this.appendAuthorizationHeader().pipe(mergeMap(
            (header: { headers?: HttpHeaders}) => this._http
                .delete<ApiResponse<T>>(environment.apiBaseUrl + path, header)
                .pipe(
                    tap(data => this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) )),
                    retry(1),
                    catchError(err => this.errorHandler<ApiResponse<T>>(err, this._toastService, jobId))
                )
        ));

    }

    // Error handling
    private errorHandlerHttpEvent<T>(error: any, toast: ToastService, jobId: string): Observable<HttpEvent<ApiResponse<T>>> {
        let errorMessage = '';

        if (error?.error?.message) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        toast.add({message: errorMessage, action: 'Stäng Felet'});

        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) );

        return throwError(() => errorMessage);
    }

    private errorHandler<T>(error: any, toast: ToastService, jobId: string): Observable<T> {
        let errorMessage = '';

        if (error?.error?.message) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        toast.add({message: errorMessage, action: 'Stäng Felet', type: 'error'});

        this.isLoadingArrSubject$.next( this.isLoadingArrSubject$.getValue().filter(x => x !== jobId) );

        return throwError(() => errorMessage);
    }

    private appendAuthorizationHeader(): Observable<{ headers?: HttpHeaders}> {

        return new Observable((observer) => {

            let header = this.httpOptions;

            // console.log("this._oauthService.hasValidAccessToken()", this._oauthService.hasValidAccessToken());

            if (this._oauthService.hasValidAccessToken()) {

                header = this.SetAuthorizationHeader( this._oauthService.getAccessToken() );

                observer.next(header);
                observer.complete();

            } else {


                this._oauthService.refreshToken().then(t => {

                    header = this.SetAuthorizationHeader(t.access_token);

                    observer.next(header);
                    observer.complete();

                }).catch(_ => {

                    observer.next(header);
                    observer.complete();
                });

            }

        });

    }


    private SetAuthorizationHeader(accessToken: string) {

        const header = this.httpOptions;

        const token = 'Bearer ' + accessToken;

        if(this.httpOptions.headers.has('Authorization')) {
            header.headers = this.httpOptions.headers.set('Authorization', token);
        } else {
            header.headers = this.httpOptions.headers.append('Authorization', token);
        }

        return header;

    }


}
