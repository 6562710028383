import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export interface DialogData {
    favoriteFilterGroupName: string;
}

@Component({
    selector: 'app-save-filter-dialog',
    templateUrl: 'save-filter-dialog.component.html',
    styleUrls: ['save-filter-dialog.component.scss'],
})
export class SaveFilterDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<SaveFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
