<ng-template #componentContent>
    <p class="m-0" *ngIf="displayHeader">
      <strong>Filter</strong>
    </p>
    <button mat-button class="align-right" color="primary" (click)="showFilterArea = !showFilterArea">{{(showFilterArea ? 'Hide filter' : 'Show filter') | translate}}</button>
    <div class="m-b-20 cgi-filter-wrapper" *ngIf="showFilterArea">
      <app-cgi-multiselect-drag [placeholderText]="'Select what you want to filter' | translate"
                                [(dataValue)]="parsedAvailableData"
                                [(selectedItems)]="selectedFilters"
                                (selectedItemsChange)="selectedFiltersChange.emit(selectedFilters)">
      </app-cgi-multiselect-drag>
    </div>


    <div class="cgi-flex-content cgi-flex-4-per-row" fxLayout="row wrap" fxLayoutGap="10px grid" fxLayout.xs="column" fxFlexFill>

      <div class="cgi-flex-item" fxFlex="25" *ngFor="let selectedItem of getSelectedItems(); index as i;" (keyup.enter)="triggerSearchChange.emit(true)">


        <mat-form-field *ngIf="(selectedItem.item_type === 'string' || selectedItem.item_type === 'number') && sharedMetaItems && getStringElemDropdownItems(selectedItem.item_id)?.length > 0" class="work-list-input-field" appearance="outline" >

          <mat-label>{{selectedItem.item_text}}</mat-label>
          <mat-select [(value)]="selectedItem.item_value">
            <mat-option *ngFor="let elemDropdownItem of getStringElemDropdownItems(selectedItem.item_id)" [value]="elemDropdownItem.value" >
              {{((elemDropdownItem.text && elemDropdownItem.text !== "") ? elemDropdownItem.text : elemDropdownItem.value) | translate}}
            </mat-option>
          </mat-select>

        </mat-form-field>

        <mat-form-field *ngIf="selectedItem.item_type === 'string' && (!sharedMetaItems || (sharedMetaItems && getStringElemDropdownItems(selectedItem.item_id)?.length === 0))" class="work-list-input-field" appearance="outline" >

          <mat-label>{{selectedItem.item_text}}</mat-label>
          <input matInput  [(ngModel)]="selectedItem.item_value" type="text" (keyup.enter)="triggerSearchChange.emit(true)" placeholder="{{'Enter' | translate}} {{selectedItem.item_text}}">

        </mat-form-field>

        <mat-form-field *ngIf="selectedItem.item_type === 'number' && (!sharedMetaItems || (sharedMetaItems && getStringElemDropdownItems(selectedItem.item_id)?.length === 0))" class="work-list-input-field" appearance="outline" >
          <mat-label>{{selectedItem.item_text}}</mat-label>
          <input matInput [(ngModel)]="selectedItem.item_value" type="number" (keyup.enter)="triggerSearchChange.emit(true)" placeholder="{{'Enter' | translate}} {{selectedItem.item_text}}">
        </mat-form-field>

        <!--
        <mat-form-field *ngIf="selectedItem.item_type === 'boolean'" class="work-list-input-field" appearance="outline" >
          <input matInput [hidden]="true" [(ngModel)]="selectedItem.item_value" name="myModel">
          <mat-checkbox class="selected-checkbox" [(ngModel)]="selectedItem.item_value">Välj {{selectedItem.item_text}}</mat-checkbox>
        </mat-form-field>
        -->
        <mat-checkbox *ngIf="selectedItem.item_type === 'boolean'" class="work-list-input-field selected-checkbox" [(ngModel)]="selectedItem.item_value">{{'Select' | translate}} {{selectedItem.item_text}}</mat-checkbox>

        <mat-form-field *ngIf="selectedItem.item_type === 'date' && rawDateFiltersValues[selectedItem.item_id]" class="work-list-datepicker" appearance="outline" >

          <mat-label>{{selectedItem.item_text}}</mat-label>
          <mat-date-range-input [rangePicker]="rangePicker">
            <input matStartDate
              placeholder="Start date"
              #dateRangeStart
              [(ngModel)]="rawDateFiltersValues[selectedItem.item_id]['start']"
              (dateChange)="dateRangeChange(selectedItem, dateRangeStart, dateRangeEnd)">
            <input matEndDate
              placeholder="End date"
              #dateRangeEnd
              [(ngModel)]="rawDateFiltersValues[selectedItem.item_id]['end']"
              (dateChange)="dateRangeChange(selectedItem, dateRangeStart, dateRangeEnd)">
          </mat-date-range-input>
          <mat-hint>{{'YYYY-MM-DD' | translate}} – {{'YYYY-MM-DD' | translate}}</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
              <button mat-button matDateRangePickerCancel>{{'Cancel' | translate}}</button>
              <button mat-raised-button color="primary" matDateRangePickerApply>{{'Apply' | translate}}</button>
            </mat-date-range-picker-actions>
          </mat-date-range-picker>

          <!--
          <mat-label>{{selectedItem.item_text}}</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="selectedItem.item_value" >
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          -->

        </mat-form-field>

      </div>

    </div>

    <div class="cgi-flex-content cgi-flex-4-per-row" fxLayout="row wrap" fxLayoutGap="10px grid" fxLayout.xs="column" fxFlexFill>

      <div class="cgi-flex-item" fxFlex="15">
        <button class="m-t-20 m-r-20" mat-flat-button color="primary" (click)="triggerSearchChange.emit(true)"><mat-icon>search</mat-icon> {{'Search' | translate}}</button>
      </div>
      <div class="cgi-flex-item" fxFlex="35">
        <button class="m-t-20 m-r-20" mat-flat-button color="primary" (click)="onClear()"><mat-icon>clear</mat-icon> {{'Clear filter' | translate}}</button>
      </div>

      <div class="cgi-flex-item" fxFlex="25" fxLayoutAlign.lg="end">
        <button class="m-t-20 m-r-20" mat-flat-button color="primary" (click)="openSaveFilterDialog()"><mat-icon>favorite</mat-icon> {{'Save search' | translate}}</button>
      </div>

      <div class="cgi-flex-item" fxFlex="25">
        <div *ngIf="groupedUserSavedFilter && groupedUserSavedFilter.length > 0">
          <mat-form-field appearance="fill">
            <mat-label>{{'Select a saved search' | translate}}</mat-label>
            <mat-select (valueChange)="changedSavedFilter($event)" [(value)]="targetSelectedUserFilter">
              <mat-select-trigger>
                    <span class="red" *ngIf="targetSelectedUserFilter">
                      {{ targetSelectedUserFilter }}
                    </span>
              </mat-select-trigger>
              <mat-option class="cgi-saved-filter-item" *ngFor="let selectedFilter of groupedUserSavedFilter" [value]="selectedFilter" >

                <div class="flex-container" fxLayout="row" fxLayoutAlign="space-between center">
                  <div class="flex-item">
                    <span>{{selectedFilter}}</span>
                  </div>
                  <div class="flex-item">
                    <span class="delete-saved-filter" (click)="deleteSavedFilter($event,selectedFilter)">
                      <mat-icon class="delete-saved-filter-icon" color="warn">clear</mat-icon>
                    </span>
                  </div>
                </div>

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>
</ng-template>
<mat-card appearance="outlined" class="cgi-card" *ngIf="useCardWrapper">
  <mat-card-content>
    <ng-container *ngTemplateOutlet="componentContent"></ng-container>
  </mat-card-content>
</mat-card>
<ng-container *ngIf="!useCardWrapper">
  <ng-container *ngTemplateOutlet="componentContent"></ng-container>
</ng-container>
