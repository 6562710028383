import {StringValueType} from './value-type.model';

export interface ResponseExaminations {
    totalNr: number;
    examinations: SpExaminationDetailsSearch[];
}
export interface SpExaminationDetailsSearch {
    /**
     * Format: int32
     * @description Gets or sets the value of the request index
     */
    requestIndex?: number;
    /** @description Gets or sets the value of the sending application */
    sendingApplication?: string;
    /**
     * Format: int32
     * @description Gets or sets the value of the producing care unit index
     */
    producingCareUnitIndex?: number;
    /**
     * Format: int32
     * @description Gets or sets the value of the referring care unit index
     */
    referringCareUnitIndex?: number;
    /** @description Gets or sets the value of the referring practitioner name */
    referringPractitionerName?: string;
    /** @description Gets or sets the value of the referring practitioner id */
    referringPractitionerId?: string;
    /** @description Gets or sets the value of the request priority */
    requestPriority?: string;
    /** @description Gets or sets the value of the question text */
    questionText?: string;
    /** @description Gets or sets the value of the question text, in a sortable database typ (nvarchar instead of ntext) */
    // sortableQuestionText?: string;
    /** @description Gets or sets the value of the request anamnesis */
    requestAnamnesis?: string;
    /** @description Gets or sets the value of the request anamnesis , in a sortable database typ (nvarchar instead of ntext) */
    // sortableRequestAnamnesis?: string;
    /** @description Gets or sets the value of the request response */
    requestResponse?: string;
    /** @description Gets or sets the value of the request response , in a sortable database typ (nvarchar instead of ntext) */
    // sortableRequestResponse?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the completed time
     */
    completedTime?: string;
    /** @description Gets or sets the value of the producing care unit hsa id */
    producingCareUnitHsaId?: string;
    /** @description Gets or sets the value of the producing unit name */
    producingUnitName?: string;
    /** @description Gets or sets the value of the producing unit hsa id */
    producingUnitHsaId?: string;
    /**
     * Format: int32
     * @description Gets or sets the value of the producing care provider index
     */
    producingCareProviderIndex?: number;
    /**
     * Format: int32
     * @description Gets or sets the value of the referring care provider index
     */
    referringCareProviderIndex?: number;
    /**
     * Format: int32
     * @description Gets or sets the value of the examination index
     */
    examinationIndex?: number;
    /** @description Gets or sets the value of the accession number */
    accessionNumber?: string;
    /** @description Gets or sets the value of the harmonized examination code */
    harmonizedExaminationCode?: string;
    /** @description Gets or sets the value of the harmonized examination name */
    harmonizedExaminationName?: string;
    /** @description Gets or sets the value of the placer examination name */
    placerExaminationName?: string;
    /** @description Gets or sets the value of the placer examination code */
    placerExaminationCode?: string;
    /** @description Gets or sets the value of the filler examination code */
    fillerExaminationCode?: string;
    /** @description Gets or sets the value of the filler examination name */
    fillerExaminationName?: string;
    /** @description Gets or sets the value of the filler request id */
    fillerRequestId?: string;
    /** @description Gets or sets the value of the examination status */
    examinationStatus?: string;
    /** @description Gets or sets the value of the study instance uid */
    studyInstanceUid?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the request time
     */
    requestTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the exam scheduled time
     */
    examScheduledTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the request approved time
     */
    requestApprovedTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the appointment time
     */
    appointmentTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the registration time
     */
    registrationTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the start time
     */
    startTime?: string;
    /**
     * Format: date-time
     * @description Gets or sets the value of the report time
     */
    reportTime?: string;
    /** @description Gets or sets the value of the collaboration state */
    collaborationState?: string;
    /** @description Gets or sets the value of the collaboration flag */
    assignmentId?: number;

    assignmentDestination?: number;

    assignmentExpectedResult: string;

    assignmentResultStatus?: string;

    assignmentDestUserResponse?: string;

    /** @description Gets or sets the value of the laterality code */
    lateralityCode?: string;
    /** @description Gets or sets the value of the extra status */
    extraStatus?: string;
    /**
     * Format: int32
     * @description Gets or sets the value of the patient index
     */
    patientIndex?: number;
    /** @description Gets or sets the value of the firstname */
    firstname?: string;
    /** @description Gets or sets the value of the lastname */
    lastname?: string;
    /** @description Gets or sets the value of the patient id */
    patientId?: string;
    /** @description Gets or sets the value of the patient idtype */
    patientIdtype?: string;
    /** @description Gets or sets the value of the gender */
    gender?: string;
    /** @description Gets or sets the value of the postal code */
    postalCode?: string;
    /** @description Gets or sets the value of the city */
    city?: string;
    /** @description Gets or sets the value of the filler order number */
    fillerOrderNumber?: string;
    /** @description Gets or sets the value of the referring unit name */
    referringUnitName?: string;
    /** @description Gets or sets the value of the body part name */
    bodyPartName?: string;

}

export const availableExaminationColumns: StringValueType[] = [
    {value: 'patientId', type: 'string'},
    {value: 'firstname', type: 'string'},
    {value: 'lastname', type: 'string'},
    {value: 'accessionNumber', type: 'string'},
    {value: 'fillerOrderNumber', type: 'string'},
    {value: 'fillerExaminationName', type: 'string'},
    {value: 'examinationStatus', type: 'string'},
    {value: 'referringUnitName', type: 'string'},
    {value: 'producingUnitName', type: 'string'},
    {value: 'requestIndex', type: 'number'},
    {value: 'sendingApplication', type: 'string'},
    {value: 'producingCareUnitIndex', type: 'number'},
    {value: 'referringCareUnitIndex', type: 'number'},
    {value: 'referringPractitionerName', type: 'string'},
    {value: 'referringPractitionerId', type: 'string'},
    {value: 'requestPriority', type: 'string'},
    {value: 'questionText', type: 'string'},
    {value: 'requestAnamnesis', type: 'string'},
    {value: 'requestResponse', type: 'string'},
    {value: 'completedTime', type: 'date'},
    {value: 'producingCareUnitHsaId', type: 'string'},
    {value: 'producingUnitHsaId', type: 'string'},
    {value: 'producingCareProviderIndex', type: 'number'},
    {value: 'referringCareProviderIndex', type: 'number'},
    {value: 'examinationIndex', type: 'number'},
    {value: 'harmonizedExaminationCode', type: 'string'},
    {value: 'harmonizedExaminationName', type: 'string'},
    {value: 'placerExaminationName', type: 'string'},
    {value: 'placerExaminationCode', type: 'string'},
    {value: 'fillerExaminationCode', type: 'string'},
    {value: 'fillerRequestId', type: 'string'},
    {value: 'studyInstanceUid', type: 'string'},
    {value: 'requestTime', type: 'date'},
    {value: 'examScheduledTime', type: 'date'},
    {value: 'requestApprovedTime', type: 'date'},
    {value: 'appointmentTime', type: 'date'},
    {value: 'registrationTime', type: 'date'},
    {value: 'startTime', type: 'date'},
    {value: 'reportTime', type: 'date'},
    {value: 'collaborationState', type: 'string'},
    {value: 'assignmentId', type: 'number'},
    {value: 'assignmentDestination', type: 'number'},
    {value: 'assignmentResultStatus', type: 'string'},
    {value: 'assignmentExpectedResult', type: 'string'},
    {value: 'assignmentDestUserResponse', type: 'string'},
    {value: 'lateralityCode', type: 'string'},
    {value: 'patientIndex', type: 'number'},
    {value: 'patientIdtype', type: 'string'},
    {value: 'gender', type: 'string'},
    {value: 'postalCode', type: 'string'},
    {value: 'city', type: 'string'},
    {value: 'bodyPartName', type: 'string'},
    {value: 'extraStatus', type: 'string'},
];
