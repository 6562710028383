  <!-- ============================================================== -->
  <!-- sidebar -->
  <!-- ============================================================== -->
  <div class="user-profile" style="background: url(assets/images/background/default-sidebar-bg.jpg) no-repeat; background-size: cover;">
    <!-- User profile image -->
    <div class="profile-img">
      <!--<img src="assets/images/users/profile.png" alt="user" (click)="handleNotify()" />-->
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
      <a [matMenuTriggerFor]="sdprofile" class="d-flex align-items-center">
        <span *ngIf="authedUser"> {{authedUser.firstname}} {{authedUser.lastname}}</span>

        <i class="ti-angle-down font-12 ml-auto"></i>
      </a>
    </div>
    <mat-menu #sdprofile="matMenu" class="mymegamenu sidebar-profile-menu">
      <!--<button mat-menu-item><mat-icon>settings</mat-icon> Settings</button>-->
      <button mat-menu-item routerLink="/profile"><mat-icon>account_box</mat-icon> {{'Profile' | translate}}</button>

      <button *ngIf="$UserHaveFunction(authedUser, [FunctionSuperAdmin])" mat-menu-item routerLink="/settings"><mat-icon>settings</mat-icon> {{'Settings' | translate}}</button>

      <button mat-menu-item *ngIf="userManualPath()" (click)="goToUserManualPath()"><mat-icon>description</mat-icon> {{'User Manual' | translate}}</button>

      <!--<button mat-menu-item><mat-icon>notifications_off</mat-icon> Disable notifications</button>-->
      <button mat-menu-item (click)="signOut($event)"><mat-icon>exit_to_app</mat-icon> {{'Log out' | translate}}</button>
    </mat-menu>
  </div>

  <mat-nav-list id="navigation-menu-list" appAccordion>
    <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems.getMenuitem(authedUser)" routerLinkActive="selected"
      disableRipple
      group="{{ menuitem.state }}"
      (click)="scrollToTop()"
    >
      <a class="menu-item" appAccordionToggle
        [routerLink]="[menuitem.state]"
        *ngIf="menuitem.type === 'link'"
        (click)="handleNotify()"
      >
        <span class="menu-item-anchor-content">
          <mat-icon>{{ menuitem.icon }}</mat-icon>
          <span>{{ menuitem.name | translate }}</span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
            badge.value
          }}</span>
        </span>
      </a>

      <a class="menu-item" appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
        <span class="menu-item-anchor-content">
          <mat-icon>{{ menuitem.icon }}</mat-icon>
          <span>{{ menuitem.name | translate }}</span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
            badge.value
          }}</span>
        </span>
      </a>

      <a
        class="menu-item"
        appAccordionToggle
        href="{{ menuitem.state }}"
        target="_blank"
        *ngIf="menuitem.type === 'extTabLink'"
      >
        <span class="menu-item-anchor-content">
          <mat-icon>{{ menuitem.icon }}</mat-icon>
          <span>{{ menuitem.name | translate }}</span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
            badge.value
          }}</span>
        </span>
      </a>

      <a class="menu-item" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
        <span class="menu-item-anchor-content">
          <mat-icon>{{ menuitem.icon }}</mat-icon>
          <span>{{ menuitem.name | translate }}</span>
          <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
            badge.value
          }}</span>
        </span>
        <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
      </a>

      <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
        <mat-list-item
          *ngFor="let childitem of menuitem.children; let j = index; let i = childitem"
          routerLinkActive="selected"
        >
          <a
            [routerLink]="['/', menuitem.state, childitem.state]"
            *ngIf="childitem.type === 'link'"
            class="relative"
            routerLinkActive="selected"
            (click)="itemSelect[i] = j"
            (click)="handleNotify()"
            >{{ childitem.name | translate }}</a
          >
        </mat-list-item>

        <mat-list-item *ngFor="let childitem of menuitem.children; let j = index; let i = childitem">
          <a
            class="menu-item"
            href="javascript: void(0);"
            *ngIf="childitem.type === 'subchild'"
            (click)="addExpandClass(childitem.name)"
            [ngClass]="{ selected: showMenu === childitem.name }"
          >
            <span>{{ childitem.name | translate }}</span>
            <span fxFlex></span>
            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
          </a>

          <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
            <mat-list-item *ngFor="let child of childitem.child" routerLinkActive="selected">
              <a
                [routerLink]="['/', menuitem.state, childitem.state, child.state]"
                routerLinkActive="selected"
                >{{ child.name | translate }}</a
              >
            </mat-list-item>
          </mat-nav-list>
        </mat-list-item>
      </mat-nav-list>

      <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
        <span>{{ menuitem.name | translate }}</span>
      </div>
    </mat-list-item>
  </mat-nav-list>
