import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
    selector: 'app-json-details-dialog',
    templateUrl: 'json-details-dialog.component.html',
    styleUrls: ['json-details-dialog.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class JsonDetailsDialogComponent {

    detail?: any;

    constructor(
        public dialogRef: MatDialogRef<JsonDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {

        this.detail = data.detail;

    }

}
