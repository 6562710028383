import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SelectItem} from '../../models/select-item.model';

@Component({
    selector: 'app-cgi-multiselect-drag',
    templateUrl: './cgi-multiselect-drag.component.html',
    styleUrls: ['./cgi-multiselect-drag.component.scss'],
})
export class CgiMultiselectDragComponent {

    /**
     * Holds the current value of the slider
     */
    @Input() placeholderText = '';


    /**
     * Holds the current value of the slider
     */
    @Input() dataValue: SelectItem[] | undefined = [];

    /**
     * Invoked when the model has been changed
     */
    @Output() dataValueChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();

    /**
     * Holds the current value of the slider
     */
    @Input() selectedItems: SelectItem[] | undefined = [];

    /**
     * Invoked when the model has been changed
     */
    @Output() selectedItemsChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();


    constructor(private cdr: ChangeDetectorRef) {
    }


    drop(event: CdkDragDrop<number>): void {
        if (event.previousContainer.data !== event.container.data) {
            moveItemInArray(this.selectedItems ?? [], event.previousContainer.data, event.container.data);
        }


    }

    remove(fruit: SelectItem): void {
        const index = this.selectedItems?.indexOf(fruit);

        if (index !== undefined && index >= 0) {
            this.selectedItems?.splice(index, 1);
        }
    }


}
