<div fxLayout="row wrap" *ngIf="exportJob">
  <div fxFlex.gt-lg="75" fxFlex.gt-md="60" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card appearance="outlined">
      <mat-card-content>

        <h3 *ngIf="exportJob?.identifier && exportJob.identifier !== ''"><strong>{{'identifier' | translate}}:</strong> {{exportJob.identifier}}</h3>
        <h4 *ngIf="exportJob?.patientId && exportJob.patientId !== ''"><strong>{{'patientId' | translate}}:</strong> {{exportJob.patientId}}</h4>

        <p *ngIf="dateHaveNotPassedToday(exportJob.startTime)"><strong>{{'Validity start' | translate}}:</strong> {{exportJob.startTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p><strong>{{'Validity end' | translate}}:</strong> {{exportJob.endTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob.maxNrDownloads > 0"><strong>{{'maxNrDownloads' | translate}}:</strong> {{exportJob.maxNrDownloads}}</p>

        <mat-card appearance="raised" class="info-card" *ngIf="exportJob?.exportFiles.length > 1 && exportJob.status === 'success'">
          <mat-card-content>
            <mat-icon class="mat-icon-card">info</mat-icon>
            <span>{{'In this export there are' | translate}} {{exportJob?.exportFiles.length}} {{'files that all need to be downloaded to get all the files' | translate}}</span>
          </mat-card-content>
        </mat-card>

        <div *ngIf="exportJob.status === 'processing'">

          <div *ngIf="exportJob.type === 'dicom'">
            <mat-progress-bar *ngIf="progressTotalFiles > 0 && progressDoneFiles > 0" mode="determinate" class="progress-bar m-t-30 m-b-30" [ngClass]="{'progress-success': ((progressDoneFiles/progressTotalFiles)*100) === 100}" [value]="((progressDoneFiles/progressTotalFiles)*100)"></mat-progress-bar>
            <p *ngIf="progressTotalFiles > 0 && progressTotalFiles !== progressDoneFiles"><strong>{{'Number of total files to handle' | translate}}:</strong> {{progressTotalFiles}}</p>
            <p *ngIf="progressDoneFiles > 0 && progressTotalFiles !== progressDoneFiles"><strong>{{'Number handled files' | translate}}:</strong> {{progressDoneFiles}}</p>
            <p *ngIf="progressTotalFiles > 0 && progressDoneFiles > 0 && progressTotalFiles === progressDoneFiles" class="text-success">{{'Files have been handled. We are now finalizing the export. Please wait a little while longer for the export to complete.' | translate}}</p>
          </div>

          <mat-card appearance="raised" class="info-card">
            <mat-card-content>
              <mat-icon class="mat-icon-card">info</mat-icon>

              <ng-container *ngIf="!exportJob?.approximateSize || exportJob?.approximateSize === 0;else first">
                <span>{{'Files are being added to this export. Depending on amount of files, this can take several minutes.' | translate}}</span>
              </ng-container>
              <ng-template #first>
                <ng-container *ngIf="exportJob?.approximateSize > 30000000000;else second">
                  <span><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} </span> <br>
                  <!--<span>{{'This will take about following minutes to prepare' | translate}}: 20min</span>-->
                </ng-container>
              </ng-template>
              <ng-template #second>
                <ng-container *ngIf="exportJob?.approximateSize > 20000000000;else third">
                  <span><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} </span> <br>
                  <!--<span>{{'This will take about following minutes to prepare' | translate}}: 10min</span>-->
                </ng-container>
              </ng-template>
              <ng-template #third>
                <ng-container *ngIf="exportJob?.approximateSize > 15000000000;else fourth">
                  <span><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} </span> <br>
                  <!--<span>{{'This will take about following minutes to prepare' | translate}}: 7min</span>-->
                </ng-container>
              </ng-template>

              <ng-template #fourth>
                <ng-container *ngIf="exportJob?.approximateSize > 10000000000;else fifth">
                  <span><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} </span> <br>
                  <!--<span>{{'This will take about following minutes to prepare' | translate}}: 5min</span>-->
                </ng-container>
              </ng-template>

              <ng-template #fifth>
                <ng-container *ngIf="exportJob?.approximateSize > 500000000;else sixth">
                  <span><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} </span> <br>
                  <!--<span>{{'This will take about following minutes to prepare' | translate}}: 2min</span>-->
                </ng-container>
              </ng-template>

              <ng-template #sixth>
                <span *ngIf="exportJob?.approximateSize > 0"><strong>{{'Files are being added to this export. Current folder Size is' | translate}}:</strong> {{getFormattedFileSize(exportJob?.approximateSize)}} <br> </span>
                <!--<span>{{'This job should take take less then a minute to prepare' | translate}}</span>-->
              </ng-template>

            </mat-card-content>
          </mat-card>

        </div>


        <div *ngIf="exportJob.status === 'success'">
          <div *ngFor="let exportFile of exportJob?.exportFiles ?? []">

            <mat-divider class="m-t-10 m-b-10"></mat-divider>

            <div fxLayout="row wrap" class="align-items-center" fxLayoutGap="16px grid">
              <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
                <div class="buttons">
                  <button mat-raised-button color="primary" class="download-btn" *ngIf="exportJob.maxNrDownloads > 0" (click)="download(exportFile.id)"><mat-icon>system_update_alt</mat-icon> {{'Download' | translate}} {{getLastPath(exportFile.destExportPath)}}</button>
                </div>
              </div>
            </div>

            <!--<p *ngIf="exportFile?.destExportPath && exportFile.destExportPath !== ''"><strong>{{'destExportPath' | translate}}:</strong> {{getLastPath(exportFile.destExportPath)}}</p>-->
            <p *ngIf="exportJob.maxNrDownloads > 0 && exportFile.nrDownloads > 0"><strong>{{'nrDownloads' | translate}}:</strong> {{exportFile.nrDownloads}}</p>

            <p><strong>{{'File size' | translate}}:</strong> {{getFormattedFileSize(exportFile?.fileSize)}}</p>

          </div>
        </div>

        <div *ngIf="$UserHaveFunction(authedUser, [FunctionImportDicom]) && (exportJob?.status === 'fail' || exportJob?.status === 'new'  || exportJob?.status === 'deleted')" fxLayout="row wrap" class="align-items-center" fxLayoutGap="16px grid">
          <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
            <div class="buttons">
              <button mat-raised-button color="primary" class="download-btn"  (click)="prepareExport()">{{'Prepare export' | translate}}</button>
            </div>
          </div>
        </div>


      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-content>
        <!--<h4>{{'Details' | translate}}</h4>-->

        <div class="buttons">
          <button mat-raised-button color="accent" (click)="showIncludedItems = !showIncludedItems">{{(showIncludedItems ? 'Hide details' : 'Show details') | translate}}</button>
          <button mat-raised-button (click)="copyLinkToClipboard()"><mat-icon>content_copy</mat-icon> {{'Copy share link to clipboard' | translate}}</button>
        </div>

      </mat-card-content>

      <mat-card-content  *ngIf="showIncludedItems" class="download-details">

        <button mat-raised-button *ngIf="$UserHaveFunction(authedUser, [FunctionImportDicom]) && exportJob?.status === 'success' && exportJob.maxNrDownloads > 0" (click)="prepareExport()">{{'Prepare export' | translate}}</button>

        <p><strong>{{'Id' | translate}}:</strong> {{exportJob.id}}</p>
        <p *ngIf="exportJob?.createdDate && exportJob.createdDate !== ''"><strong>{{'createdDate' | translate}}:</strong> {{exportJob.createdDate | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob?.createdBy && exportJob.createdBy !== ''"><strong>{{'createdBy' | translate}}:</strong> {{exportJob.createdBy}}</p>
        <p *ngIf="exportJob?.updatedDate && exportJob.updatedDate !== ''"><strong>{{'updatedDate' | translate}}:</strong> {{exportJob.updatedDate | date: 'yyyy-MM-dd HH:mm'}}</p>
        <p *ngIf="exportJob?.errorMessage && exportJob.errorMessage !== ''"><strong>{{'errorMessage' | translate}}:</strong> {{exportJob.errorMessage}}</p>
        <!--<p *ngIf="exportJob?.destExportPath && exportJob.destExportPath !== ''"><strong>{{'destExportPath' | translate}}:</strong> {{exportJob.destExportPath}}</p>-->
        <div *ngFor="let exportFile of exportJob?.exportFiles ?? []">
          <mat-divider></mat-divider>
          <p *ngIf="exportFile?.destExportPath && exportFile.destExportPath !== ''"><strong>{{'destExportPath' | translate}}:</strong> {{getLastPath(exportFile.destExportPath)}}</p>
          <p *ngIf="exportFile?.fileReadyTime && exportFile.fileReadyTime !== ''"><strong>{{'fileReadyTime' | translate}}:</strong> {{exportFile.fileReadyTime | date: 'yyyy-MM-dd HH:mm'}}</p>
          <p *ngIf="exportFile?.fileDeletedTime && exportFile.fileDeletedTime !== ''"><strong>{{'fileDeletedTime' | translate}}:</strong> {{exportFile.fileDeletedTime | date: 'yyyy-MM-dd HH:mm'}}</p>
        </div>

        <mat-divider></mat-divider>

        <span>{{'Items included in download' | translate}}:</span>
        <mat-list class="example-list-wrapping">
          <mat-list-item *ngFor="let step of exportJob.exportJobSteps ?? []" class="included-item">
            <span matListItemTitle><strong>{{step.stepIdentifier}}</strong></span>
            <span matListItemLine *ngIf="step?.archive && step.archive !== ''"><strong>{{'Archive' | translate}}:</strong> {{step.archive}}</span>
            <span matListItemLine *ngIf="step?.stepDir && step.stepDir !== ''"><strong>{{'stepDir' | translate}}:</strong>: {{step.stepDir}}</span>
            <span matListItemLine *ngIf="getJobStepMessages(step)">
               <button mat-mini-fab color="primary" (click)="showDetails(step)">
                <mat-icon>visibility</mat-icon>
              </button>
              <!--<strong>{{'stepMessage' | translate}}:</strong> {{step.stepMessage}}-->
            </span>
          </mat-list-item>
        </mat-list>

      </mat-card-content>
    </mat-card>
  </div>

  <div fxFlex.gt-lg="25" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
    <mat-card appearance="outlined">
      <mat-card-content class="bg-dark">

        <p><strong>{{'Type' | translate | toFirstUpperCase}}:</strong> {{exportJob.type | toFirstUpperCase}}</p>
        <p><strong>{{'Format' | translate | toFirstUpperCase}}:</strong> {{exportJob.format | toFirstUpperCase}}</p>

        <h3 class="label-status" [ngClass]="{
        'bg-warning': exportJob?.status === 'new',
        'bg-danger': exportJob?.status === 'fail',
        'bg-primary': exportJob?.status === 'processing',
        'bg-success': exportJob?.status === 'success',
        'bg-info': exportJob?.status === 'deleted',
        }"
        >{{exportJob.status | translate | toFirstUpperCase}}</h3>
      </mat-card-content>
      <mat-card-content>
        <mat-progress-spinner *ngIf="exportJob.status !== 'success'"
          class="export-spinner"
          [color]="getSpinnerColor()"
          [mode]="getSpinnerMode()"
          [value]="getSpinnerValue()"> 100%
        </mat-progress-spinner>
      </mat-card-content>
    </mat-card>
  </div>
</div>
