import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectItem} from '../../models/select-item.model';
import {SharedMetaItem} from '../../models/shared.model';
import {User, UserSetting, UserSettingDetail} from '../../models/user.model';
import {ApiService} from '../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {StringValueType} from '../../models/value-type.model';
import {SaveFilterDialogComponent} from './save-filter-dialog/save-filter-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-cgi-filter',
    templateUrl: './cgi-filter.component.html',
    styleUrls: ['./cgi-filter.component.scss'],
})
export class CgiFilterComponent implements OnDestroy, OnInit, OnChanges {

    showFilterArea = false;

    targetSelectedUserFilter?: string;

    groupedUserSavedFilter?: string[];

    authedUser?: User;

    translations?: any;

    /**
     * Holds the current value of the slider
     */
    @Input() filterPageType?: 'saved_filter_work_list' | 'saved_filter_work_list_send';

    /**
     * Holds the current value of the slider
     */
    @Input() parsedAvailableData?: SelectItem[];

    /**
     * Holds the current value of the slider
     */
    @Input() rawAvailableData?: StringValueType[];

    /**
     * Holds the current value of the slider
     */
    @Input() selectedFilters?: SelectItem[];

    /**
     * Holds the current value of the slider
     */
    @Input() sharedMetaItems: SharedMetaItem[] | undefined;

    /**
     * Whether or not to wrap the contents of this component in <mat-card>
     */
    @Input() useCardWrapper?: boolean = true;

    /**
     * Whether or not to display the header "Filter" at the top of the component
     */
    @Input() displayHeader?: boolean = true;

    /**
     * Invoked when the model has been changed
     */
    @Output() selectedFiltersChange: EventEmitter<SelectItem> = new EventEmitter<SelectItem>();

    /**
     * Invoked when the model has been changed
     */
    @Output() triggerSearchChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Invoked when the model has been changed
     */
    @Output() triggerClearChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() savedFiltersSelected: EventEmitter<null> = new EventEmitter<null>();


    disposable?: Subscription;

    // Used to store values for initializing date filters with existing values
    // but the corresponding SelectItem is populated with a string value.
    protected rawDateFiltersValues: Record<string, {start: Date | null, end: Date | null}> = {};

    constructor(private cdr: ChangeDetectorRef,
                private _apiService: ApiService,
                private _toastService: ToastService,
                private _userService: UserService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
    ) {
    }

    ngOnInit(): void {

        this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
            this.authedUser = x;

            this.groupedUserSavedFilter = this.authedUser?.userSettings?.userSettingDetails?.filter(y => y.type === this.filterPageType)
                .map(z => z.groupName)
                .filter((elem, index, self) => index === self.indexOf(elem)) as string [] | undefined;

        });
        this.updateRawDateFiltersValues();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedFilters']) {
            this.updateRawDateFiltersValues();
        }
    }

    private updateRawDateFiltersValues() {
        if (this.selectedFilters?.length) {
            this.selectedFilters.forEach(f => {
                if (f.item_type === 'date') {
                    if (f.item_value === undefined) {
                        this.rawDateFiltersValues[f.item_id] = {start: null, end: null};
                    } else if (typeof f.item_value === "string") {
                        const dateSplit: string[] = f.item_value.split(',');
                        const maybeStartDate = new Date(dateSplit[0]);
                        const maybeEndDate = new Date(dateSplit[1]);
                        this.rawDateFiltersValues[f.item_id] = {
                            start: isNaN(maybeStartDate.getTime()) ? null : maybeStartDate,
                            end: isNaN(maybeEndDate.getTime()) ? null : maybeEndDate,
                        }
                    }
                }
            });
        }
    }

    getSelectedItems(): SelectItem[] {
        for (const c of this.rawAvailableData ?? []) {
            const found = this.selectedFilters?.find(x => x.item_id === c.value);

            if(found)
            {found.item_type = c.type;}
        }
        return this.selectedFilters ?? [];
    }


    changedSavedFilter(selectedGroupName: any): void {

        this.onClear(false);
        if(selectedGroupName) {

            const savedFilters = this.authedUser?.userSettings?.userSettingDetails?.filter(x => x.type === this.filterPageType && x.groupName === selectedGroupName);

            if(savedFilters) {
                for(const savedFilter of savedFilters) {
                    if (savedFilter.value && savedFilter.key) {
                        const matchingSelectedFilter: SelectItem | undefined = this.selectedFilters?.find(f => f.item_id === savedFilter.key);
                        if (matchingSelectedFilter) {
                            matchingSelectedFilter.item_value = savedFilter.value;
                        } else {
                            const savedFilterPreset: SelectItem | undefined = this.parsedAvailableData?.find(x => x.item_id === savedFilter.key);
                            if (savedFilterPreset) {
                                this.selectedFilters?.push({
                                    item_id: savedFilter.key,
                                    item_value: savedFilter.value,
                                    item_text: savedFilterPreset.item_text, // The responsibility to translate this text should be on the parent component.
                                    item_type: savedFilterPreset.item_type
                                });
                            }
                        }
                    }
                }
                this.savedFiltersSelected.emit();
                this.updateRawDateFiltersValues();
            }
        }
    }

    deleteSavedFilter($event: MouseEvent, groupName: string): void {

        this._apiService.delete<string>('/user/setting/favorite_filter?groupName='+groupName).subscribe({
            next: (res) => {
                console.log('/user/setting/body res', res);
                const responseMessageToTranslate = res.data.split(groupName)[1].trim();
                this._translate.get(responseMessageToTranslate).subscribe(trans => {
                    this._toastService.add({message: `${groupName} ${trans}`});
                });
                const newList = this.authedUser?.userSettings?.userSettingDetails?.filter(x => x.type === this.filterPageType && x.groupName !== groupName);
                this._userService.updateUserSettingDetails(newList);
            },
            error: (error: any) => {
                console.log('Error deleting filter', error);
            }
        });

    }

    parseDateRangeToString(startDate: Date, endDate: Date): string {
        let dateRangeString: string = '';
        const maybeStartDate = HelperService.getValidatedDateString(HelperService.getDateAsString(startDate));
        const maybeEndDate = HelperService.getValidatedDateString(HelperService.getDateAsString(endDate));
        if (maybeStartDate) {
            dateRangeString = maybeStartDate;
            if (maybeEndDate) {
                dateRangeString += `,${maybeEndDate}`;
            }
        }
        return dateRangeString;
    }

    dateRangeChange(selectedItem: SelectItem, dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
        const dateRangeString = this.parseDateRangeToString(
            new Date(dateRangeStart.value || ''),
            new Date(dateRangeEnd.value || '')
        );
        selectedItem.item_value = (dateRangeString === '') ? undefined : dateRangeString;
    }

    getStringElemDropdownItems(fieldItemId: string): SharedMetaItem[] | undefined {
        return this.sharedMetaItems?.filter(x => x.type === 'dropdown' && x.key === fieldItemId)
            .sort((a,b) => ((a.text || a.value || '') > (b.text || b.value || '') ? 1 : (((b.text || b.value || '') > (a.text || a.value || '')) ? -1 : 0)));
    }

    onClear(isLoadNewData: boolean = true): void {

        for (const item of this.selectedFilters ?? []) {
            item.item_value = '';
        }
        for(const key of Object.keys(this.rawDateFiltersValues)) {
            this.rawDateFiltersValues[key] = {start: null, end: null};
        }

        this.targetSelectedUserFilter = undefined;

        this.triggerClearChange.emit(isLoadNewData);
    }


    openSaveFilterDialog(): void {

        const selectedFilterWithValue = this.selectedFilters?.filter(x => x.item_value);

        if(selectedFilterWithValue?.length === 0) {
            this._toastService.add({message: 'No search with value to save'});
            return;
        }

        const dialogRef = this._dialog.open(SaveFilterDialogComponent, {
            data: {favoriteFilterGroupName: ''},
        });

        dialogRef.afterClosed().subscribe((typedGroupName?: string) => {

            console.log('The dialog was closed', typedGroupName);

            if(!typedGroupName)
            {return;}

            const body: UserSettingDetail[] | undefined = selectedFilterWithValue?.map((value) => ({
                type: this.filterPageType,
                key: value.item_id,
                value: value.item_value as string,
                groupName: typedGroupName
            }));

            if(!body)
            {return;}

            this._apiService.post<UserSetting>('/user/setting/favorite_filter', body).subscribe({
                next: (res) => {
                    console.log('/user/setting/body res', res);

                    console.log('data users', res.data);

                    this._userService.updateUserSetting(res.data);
                    this.targetSelectedUserFilter = typedGroupName;
                    const length: number = res.data.userSettingDetails?.filter(x => x.groupName === typedGroupName).length ?? 0;
                    this._toastService.add({message: length > 1 ? 'New search items added for the group' : 'New search item added for the group', additionalInfo: typedGroupName, type: 'success'});

                },
                error: (error: any) => {
                    console.log('Error saving filter', error);
                }
            });


        });
    }

    ngOnDestroy(): void {
        this.disposable?.unsubscribe();
    }



}
