import {MenuItems} from './items/menu-items';
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './accordion';
import {CdkDetailRowDirective} from './directive/cdk-detail-row.directive';
import {CommonModule} from '@angular/common';
import {CdkDrag, CdkDropList, CdkDropListGroup} from '@angular/cdk/drag-drop';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CgiMultiselectComponent} from './component/cgi-multiselect/cgi-multiselect.component';
import {ConfirmDialogComponent} from './dialog/confirm-dialog/confirm-send-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from './material-module';
import {OrderByPipe} from './pipes/order-by.pipe';
import {FilterByPipe} from './pipes/filter.pipe';
import {CgiMultiselectDragComponent} from './component/cgi-multiselect-drag/cgi-multiselect-drag.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {MatSelectModule} from '@angular/material/select';
import {CgiSearchSelectComponent} from './component/cgi-select-lazy-load/cgi-search-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {JsonDetailsDialogComponent} from './dialog/json-details-dialog/json-details-dialog.component';
import {StringDetailsDialogComponent} from './dialog/string-details-dialog/string-details-dialog.component';
import {CgiFilterComponent} from './component/cgi-filter/cgi-filter.component';
import {FlexModule} from '@angular/flex-layout';
import {SaveFilterDialogComponent} from './component/cgi-filter/save-filter-dialog/save-filter-dialog.component';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { FirstUpperCasePipe } from './pipes/first-uppercase-pipe';
import {CgiDestinationTreeComponent} from './component/cgi-destination-tree/cgi-destination-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {GenderPipe} from './pipes/gender-pipe';
import {DicomJobResendDialogComponent} from './dialog/dicom-job-resend-dialog/dicom-job-resend-dialog.component';
import {PrettyHtmlPipe} from './pipes/pretty-html-pipe';
import {PrettyDetailsDialogComponent} from './dialog/pretty-details-dialog/pretty-details-dialog.component';
import {HtmlDialogComponent} from './dialog/html-dialog/html-dialog.component';
import {PrettyDicomPatientNamePipe} from './pipes/pretty-dicom-patient-name-pipe';
import {CgiMatPaginatorComponent} from './component/cgi-mat-pagination/cgi-mat-paginator.component';
import {OrderByKeyValuePipe} from './pipes/order-by-key-value.pipe';
import { AdjustableColumnWidthDirective } from './directive/adjustable-table-column-width.directive';
import { IsOverflowingPipe } from './pipes/is-overflowing-pipe';
import {DicomDivDirective} from './directive/dicom-div.directive';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SliceTextPipe} from './pipes/sliceText.pipe';
import {ToastContainerComponent} from './component/toast-container/toast-container.component';



@NgModule({
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        CdkDetailRowDirective,
        CgiDestinationTreeComponent,
        CgiMultiselectDragComponent,
        CgiMultiselectComponent,
        CgiFilterComponent,
        SaveFilterDialogComponent,
        ConfirmDialogComponent,
        DicomJobResendDialogComponent,
        JsonDetailsDialogComponent,
        PrettyDetailsDialogComponent,
        StringDetailsDialogComponent,
        HtmlDialogComponent,
        OrderByPipe,
        OrderByKeyValuePipe,
        FilterByPipe,
        SliceTextPipe,
        CgiSearchSelectComponent,
        YesNoPipe,
        FirstUpperCasePipe,
        PrettyDicomPatientNamePipe,
        GenderPipe,
        PrettyHtmlPipe,
        CgiMatPaginatorComponent,
        AdjustableColumnWidthDirective,
        IsOverflowingPipe,
        DicomDivDirective,
        ToastContainerComponent
    ],
    imports: [
        CommonModule,
        CdkDropList,
        CdkDrag,
        CdkDropListGroup,
        FormsModule,
        TranslateModule,
        MatTreeModule,
        MaterialModule,
        MultiSelectModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FlexModule
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        CdkDetailRowDirective,
        CgiDestinationTreeComponent,
        CgiMultiselectDragComponent,
        CgiMultiselectComponent,
        CgiSearchSelectComponent,
        ConfirmDialogComponent,
        DicomJobResendDialogComponent,
        CgiFilterComponent,
        JsonDetailsDialogComponent,
        PrettyDetailsDialogComponent,
        StringDetailsDialogComponent,
        HtmlDialogComponent,
        FilterByPipe,
        SliceTextPipe,
        YesNoPipe,
        FirstUpperCasePipe,
        PrettyDicomPatientNamePipe,
        GenderPipe,
        PrettyHtmlPipe,
        CgiMatPaginatorComponent,
        OrderByPipe,
        OrderByKeyValuePipe,
        AdjustableColumnWidthDirective,
        IsOverflowingPipe,
        DicomDivDirective,
        ToastContainerComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [MenuItems, IsOverflowingPipe],
})
export class SharedModule {}
