import {AfterViewInit, ChangeDetectorRef, ContentChildren, Directive, ElementRef, OnDestroy, QueryList} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { IsOverflowingPipe } from '../pipes/is-overflowing-pipe';

@Directive({
    selector: '[appAdjustableColumnWidth]'
})
export class AdjustableColumnWidthDirective implements AfterViewInit,  OnDestroy {

    private resizeListener = (e: MouseEvent) => {
        const target: HTMLElement = (e.target as HTMLElement);
        const thParent: HTMLElement = (this.getClosestParentOfType(target, 'th') as HTMLElement);
        // Temporarily set a high z-index, visible overflow, and a background color equal to the
        // table's background color to allow the resize "grabber" to render on top of other columns while resizing.
        if (thParent) {
            thParent.classList.add('resize-in-progress');
        }
        // Add a listener to catch when mouse is released anywhere on the page
        // which will only trigger once and then be removed.
        // This is needed as listening to the mouseup event directly will only trigger
        // the listener to run when mouse is released *on* the column which is being resized.
        document.addEventListener('mouseup', () => {
            // Remove temporary styling applied above.
            if (thParent) {
                thParent.classList.remove('resize-in-progress');
            }
            
            // If the current width is registered, dont trigger resize logic if the width of the column has not changed.
            if (this.currentColumnWidths[target.id]
                ? target.style.width && (this.currentColumnWidths[target.id] !== target.style.width)
                : true
            ) {
                this.resizeTableColumn(target);
                this.matTable?.updateStickyColumnStyles();
            }
        }, {once: true});
    }

    @ContentChildren('resizeColumn', {descendants: true})
    private resizeableColumnContainers?: QueryList<ElementRef>;

    // Map of column element id to width style.
    private currentColumnWidths: Record<string, string> = {};
    private overrideColumnWidths?: Record<string, string> = undefined;

    constructor(private tableRef: ElementRef, private matTable: MatTable<HTMLTableElement>, private isOverflowing: IsOverflowingPipe) {
        this.tableRef.nativeElement.classList.add('table-adjustable-columns');
    }

    ngAfterViewInit(): void {
        this.resizeableColumnContainers?.changes.subscribe((changes: QueryList<ElementRef<HTMLDivElement>>) => {
            // Apply any set overrides to the column widths. Put here mainly for initialization purposes.
            if (this.overrideColumnWidths && this.resizeableColumnContainers?.length) {
                this.resizeableColumnContainers.toArray().map(c => c.nativeElement).forEach((entry: HTMLElement) => {
                    if (entry.id.length && this.overrideColumnWidths![entry.id]) {
                        entry.style.width = this.overrideColumnWidths![entry.id];
                    }
                });
                this.overrideColumnWidths = undefined;
            }
            // Listen for width/height changes for each column header.
            changes.forEach((element: ElementRef<HTMLDivElement>) => {
                // Only listen for changes in column headers for this table.
                if (this.getClosestParentOfType(element.nativeElement, 'table')?.isSameNode(this.tableRef.nativeElement)) {
                    const eventId: string = 'resize-listener';
                    if (!element.nativeElement.hasAttribute(eventId)) {
                        element.nativeElement.addEventListener("mousedown", this.resizeListener);
                        element.nativeElement.setAttribute(eventId, '');
                        element.nativeElement.style.width = `${element.nativeElement.clientWidth}px`;
                    }
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.resizeableColumnContainers?.forEach((e: ElementRef<HTMLDivElement>) => {
            e.nativeElement.removeEventListener("mousedown", this.resizeListener);
        })
    }

    private getClosestParentOfType(element: Element, type: string): ParentNode | undefined | null {
        let parentRow: ParentNode | undefined | null = element.parentNode;
        while (parentRow && (parentRow?.nodeName.toLowerCase() !== type.toLowerCase())) {
            parentRow = parentRow.parentNode;
        }
        return parentRow;
    };

    private resizeTableColumn(entry: HTMLElement): void {
        const parentRow = this.getClosestParentOfType(entry, 'tr');
        if (parentRow) {
            let columnIndex: number;
            let newColumnWidth: string;
            // Find index of column in row in order to find and resize corresponding cells in the table body.
            Array.from(parentRow.children).forEach((th, index) => {
                if (th.querySelector('div.resize-column') === entry) {
                    columnIndex = index;
                    newColumnWidth = `${entry.clientWidth}px`;
                    const parentTable: ParentNode | null | undefined = this.getClosestParentOfType((parentRow as Element), 'table');
                    if (parentTable) {
                        const tableBody = parentTable.querySelector('tbody');
                        if (tableBody) {
                            const tableHeader = this.getClosestParentOfType(entry, 'th');
                            if (tableHeader) {
                                const thElement = (tableHeader as HTMLElement);
                                thElement.style.width = `${entry.clientWidth}px`;
                                // Resize th to fit content if content is overflowing.
                                if (this.isOverflowing.transform(thElement)) {
                                    thElement.style.width = `${thElement.scrollWidth}px`;
                                }
                            }
                            Array.from(tableBody.children).forEach((row) => {
                                const cellToResize: HTMLDivElement = (row.children[columnIndex]?.querySelector('div.resize-cell') as HTMLDivElement);
                                if (cellToResize) {
                                    if (cellToResize.clientWidth !== entry.clientWidth) {
                                        cellToResize.style.width = newColumnWidth;
                                    }
                                }
                            });
                            if (entry.id?.length) {
                                this.currentColumnWidths[entry.id] = newColumnWidth;
                            }
                        }
                    }
                }
            });
        }
    }

    public setOverrideColumnWidths(overrideColumnWidths: Record<string, string>): void {
        this.overrideColumnWidths = overrideColumnWidths;
    }

    public updateColumnWidth(): void {
        if (this.resizeableColumnContainers) {
            this.resizeableColumnContainers!.toArray().forEach(e => {
                this.resizeTableColumn(e.nativeElement);
            }); 
        }
    }

    public updateStickyColumnStyle(): void {
        this.matTable?.updateStickyColumnStyles();
    }

    public getCurrentColumnWidths(): Record<string, string> {
        return this.currentColumnWidths;
    }
}
