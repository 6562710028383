import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Subject } from 'rxjs';

export interface Toast {
    message: string;
    additionalInfo?: string;
    duration?: number;
    action?: string;
    type?: 'info' | 'success' | 'error';
    id?: string;
    onAction?: () => void;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {

    private _toasts: Toast[] = [];
    private toastsChangedSource: Subject<Toast[]> = new Subject<Toast[]>();
    private readonly DEFAULT_DURATION = 12000;
    private usedRandomIds: string[] = [];
    public toastsChanged = this.toastsChangedSource.asObservable();

    constructor(private _translate: TranslateService) {}

    // Generates a "weak" random identifier, but there is no need for cryptographically strong randomness in this context.
    private generateRandomId(): string {
        const MAX: number = Number.MAX_SAFE_INTEGER;
        let id = '';
        while(id === '') {
            const candidate: string = Math.round(Math.random() * MAX).toString();
            if (this.usedRandomIds.indexOf(candidate) === -1) {
                id = candidate;
            }
        }
        this.usedRandomIds.push(id);
        return id;
    }

    public add(toast: Toast): void {
        // Always override any user provided id so that we don't have to deal with potential duplicate ids.
        toast.id = this.generateRandomId();
        this._translate.get([toast.message, 'Close']).subscribe(trans => {
            toast.action = trans.Close ?? toast.action;
            toast.message = trans[toast.message] ?? toast.message;
            if(toast.additionalInfo) { toast.message += `: ${toast.additionalInfo}`; }
            this._toasts.push(toast);
            this.toastsChangedSource.next(this._toasts);
            setTimeout(() => {
                this.remove(toast);
            }, toast.duration || this.DEFAULT_DURATION);
        });
    }


    public remove(toast: Toast): void {
        this._toasts = this._toasts.filter((t) => t.id != toast.id);
        // Remove toast id from the list of used random ids.
        const idIndex: number = this.usedRandomIds.indexOf(toast.id!);
        if (idIndex > -1) { this.usedRandomIds.splice(idIndex, 1); }
        this.toastsChangedSource.next(this._toasts);
    }
}
