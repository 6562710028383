import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output,} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {MenuItems} from '../../../shared/items/menu-items';
import {$UserHaveFunction, FunctionImportDicom, FunctionSuperAdmin, User} from '../../../shared/models/user.model';
import {UserService} from '../../../shared/services/user.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnDestroy {
    mobileQuery: MediaQueryList;

    authedUser: User | undefined;

    currentLang?: string;

    @Input() showClass = false;
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        private _userService: UserService,
        public menuItems: MenuItems,
        private _translate: TranslateService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        this._userService.listenToAuthedUser().subscribe(x => {
            this.authedUser = x;
        });


        this._translate.onDefaultLangChange.subscribe(c => {
            this.currentLang = c.lang;
        });

    }

    private _mobileQueryListener: () => void;
    status = true;
    showMenu = '';
    itemSelect: number[] = [];
    parentIndex = 0;
    childIndex = 0;

    addExpandClass(element: any): void {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    subclickEvent(): void {
        this.status = true;
    }
    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        });
    }

    ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    handleNotify(): void {
        this.notify.emit(!this.showClass);
    }

    signOut(event: MouseEvent): void {
        this._userService.logOut();
    }

    userManualPath(): string | undefined {

        let path = '';
        if(this.currentLang === 'sv') {
            path = environment.pathToManualSv;
        } else if (this.currentLang === 'en') {
            path = environment.pathToManualEn;
        } else if (this.currentLang === 'dk') {
            path = environment.pathToManualDk;
        }

        if(!path || path === '')
        {return undefined;}

        return path;
    }

    goToUserManualPath(): void {
        const url = window.location.origin + this.userManualPath();
        window.open(url, '_blank');
    }

    protected readonly $UserHaveFunction = $UserHaveFunction;
    protected readonly FunctionSuperAdmin = FunctionSuperAdmin;
}
