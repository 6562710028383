import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

export interface ImageEvent {
    error?: Error;
    image?: any;
    imageId: string;
}


@Directive({
    selector: '[appDicomDiv]'
})
export class DicomDivDirective {


    @Output() imageChange: EventEmitter<ImageEvent> = new EventEmitter<ImageEvent>();

    constructor(private el: ElementRef) {
    // console.log('el', this.el);
    }


    @HostListener('csEventChange', ['$event.target'])
    csEventChange(event: ImageEvent) {
    // console.log("csEventChange", event);
        this.imageChange.emit(event);

    }


}
