import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService, Toast } from 'src/app/shared/services/toast.service';

@Component({
    selector: 'app-toast-container',
    templateUrl: './toast-container.component.html',
    styleUrl: './toast-container.component.scss',
})
export class ToastContainerComponent implements OnDestroy {
    protected toastsToShow: Toast[] = [];
    private toastsToShowChangedSubscription: Subscription;
    constructor(
        protected _toastService: ToastService,
        private _cdr: ChangeDetectorRef
    ) {
        this.toastsToShowChangedSubscription =
      this._toastService.toastsChanged.subscribe(
          (updatedToasts: Toast[]) => {
              this.toastsToShow = updatedToasts;
              this._cdr.detectChanges();
          }
      );
    }

    ngOnDestroy(): void {
        this.toastsToShowChangedSubscription.unsubscribe();
    }

    protected remove(toast: Toast): void {
        document.getElementById('toast-'+ toast.id!)?.classList.add('toast-fadeout');
        if (toast.onAction) {
            toast.onAction();
        }
        // The delay has to match the animation speed specified in the toast-fadeout CSS class.
        setTimeout(() => {
            this._toastService.remove(toast);
        }, 90);
    }
}
