import {ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Observer, Subscription} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {map} from 'rxjs/operators';

@Pipe({
    name: 'toParsedGender',
    pure: false
})
export class GenderPipe implements PipeTransform, OnDestroy {

    private asyncPipe: AsyncPipe;

    constructor(private injector: Injector, private _translate: TranslateService) {
        this.asyncPipe = new AsyncPipe(injector.get(ChangeDetectorRef));
    }

    ngOnDestroy() {
        this.asyncPipe.ngOnDestroy();
    }

    transform(key: string): string {

        if(!key || key === '')
        {return 'Unknown ('+key+')';}

        return this.asyncPipe.transform(this.transformGender(key)) ?? 'Unknown ('+key+')';
    }

    transformGender(key: string): Observable<string> {

        const lowerKey = key.toLowerCase();
        let parsedFullGender = lowerKey;

        if(lowerKey === 'm')
        {parsedFullGender = 'Male';}

        if(lowerKey === 'f')
        {parsedFullGender = 'Female';}

        if(lowerKey === 'u')
        {parsedFullGender = 'Unknown';}

        if(lowerKey === 'o')
        {parsedFullGender = 'Others';}

        return this._translate.get([parsedFullGender]).pipe(
            map((translations) => translations[parsedFullGender] ?? 'Unknown t ('+parsedFullGender+')')
        );

    }


}
