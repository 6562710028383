import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TreeUnitNode } from '../models/ct-unit.model';
import { SpExaminationDetailsSearch } from '../models/sp-examination.model';
import { ExaminationAndPriors } from 'src/app/pages/collaboration-portal/work-list/work-list.component';
import { SelectItem } from '../models/select-item.model';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    private themeChangedSource = new Subject<Theme>();
    private examinationDestinationChangedSource = new Subject<TreeUnitNode | undefined>();
    private commentMessageTemplateChangedSource = new Subject<string | undefined>();
    private workListStateChangedSource = new Subject<WorkListState | undefined>();
    private sentListSelectedFiltersChangedSource = new Subject<SelectItem[] | undefined>();
    private workListColumnWidthChangedSource = new Subject<Record<string, string> | undefined>();
    private sentListColumnWidthChangedSource = new Subject<Record<string, string> | undefined>();

    // Default theme to light theme.
    private _currentTheme: Theme = Theme.Light;
    private _currentExaminationDestination: TreeUnitNode | undefined = undefined;
    private _currentCommentMessageTemplate: string | undefined = undefined;
    private _workListState: WorkListState | undefined = undefined;
    private _sentListSelectedFilters: SelectItem[] | undefined = undefined;
    // Separate from _workListState to simplify code for updating this state.
    private _workListColumnWidth: Record<string, string> | undefined = undefined;
    private _sentListColumnWidth: Record<string, string> | undefined = undefined;

    public readonly THEME_SETTING_LOCAL_STORAGE_KEY: string = 'theme-setting';

    public themeChanged$ = this.themeChangedSource.asObservable();
    public examinationDestinationChanged$ = this.examinationDestinationChangedSource.asObservable();
    public selectedCommentMessageTemplateChanged$ = this.commentMessageTemplateChangedSource.asObservable();
    public workListStateChanged$ = this.workListStateChangedSource.asObservable();
    public sentListSelectedFiltersChanged$ = this.sentListSelectedFiltersChangedSource.asObservable();
    public workListColumnWidthChanged$ = this.workListColumnWidthChangedSource.asObservable();
    public sentListColumnWidthChanged$ = this.sentListColumnWidthChangedSource.asObservable();

    changeTheme(theme: Theme): void {
        this._currentTheme = theme;
        this.themeChangedSource.next(theme);
    }

    get currentTheme(): Theme {
        return this._currentTheme;
    }

    changeExaminationDestination(destination: TreeUnitNode | undefined): void {
        this._currentExaminationDestination = destination;
        this.examinationDestinationChangedSource.next(destination);
    }

    get currentExaminationDestination(): TreeUnitNode | undefined {
        return this._currentExaminationDestination;
    }

    changeCommentMessageTemplate(messageTemplate: string | undefined): void {
        this._currentCommentMessageTemplate = messageTemplate;
        this.commentMessageTemplateChangedSource.next(messageTemplate);
    }

    get currentCommentMessageTemplate(): string | undefined {
        return this._currentCommentMessageTemplate;
    }

    changeWorkListState(workListState: WorkListState | undefined): void {
        this._workListState = workListState;
        this.workListStateChangedSource.next(workListState);
    }

    get currentWorkListState(): WorkListState | undefined {
        return this._workListState;
    }

    changeSentListSelectedFilters(selectedFilters: SelectItem[] | undefined): void {
        this._sentListSelectedFilters = selectedFilters;
        this.sentListSelectedFiltersChangedSource.next(selectedFilters);
    }

    get currentSentListSelectedFilters(): SelectItem[] | undefined {
        return this._sentListSelectedFilters;
    }

    changeWorkListColumnWidths(newColumnWidths: Record<string, string> | undefined): void {
        this._workListColumnWidth = newColumnWidths;
        this.workListColumnWidthChangedSource.next(newColumnWidths);
    }

    get currentWorkListColumnWidths(): Record<string, string> | undefined {
        return this._workListColumnWidth;
    }

    changeSentListColumnWidths(newColumnWidths: Record<string, string> | undefined): void {
        this._sentListColumnWidth = newColumnWidths;
        this.sentListColumnWidthChangedSource.next(newColumnWidths);
    }

    get currentSentListColumnWidths(): Record<string, string> | undefined {
        return this._sentListColumnWidth;
    }

    clearAllStates(): void {
        this.changeExaminationDestination(undefined);
        this.changeCommentMessageTemplate(undefined);
        this.changeWorkListState(undefined);
        this.changeSentListSelectedFilters(undefined);
        this.changeWorkListColumnWidths(undefined);
        this.changeSentListColumnWidths(undefined);
    }
}

export enum Theme {
    Light = 'light',
    Dark = 'dark'
}

export interface WorkListState {
    targetExam: SpExaminationDetailsSearch | undefined;
    selectedExaminations: ExaminationAndPriors[];
    comment?: string;
    selectedFilters: SelectItem[] | undefined;
    paginationPageSize: number | undefined;
    paginationPageIndex: number | undefined;
}
