import {Injectable} from '@angular/core';
import {UserSettingDetail} from '../models/user.model';
import {SelectItem} from '../models/select-item.model';
import {SpExaminationDetailsSearch} from '../models/sp-examination.model';
import {SpExaminationsSent} from '../models/sp-sent-examination.model';
import {PriorExaminationsDto} from '../models/examinations.model';
import {MatTableDataSource} from '@angular/material/table';
import {CheckDicomImageDto} from '../models/check-dto.model';
import {DicomGroupClaimDto, DicomGroupDto, EaDicomGroupDto} from '../models/dicom-device.model';
import {ResponseRequestOverview} from '../models/request-overview.model';
import {BehaviorSubject, Observable} from 'rxjs';

export type Nullable<T> = T | undefined;

export const DATEPICKER_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

export const DATEPICKER_FORMATS2 = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

interface MultiselectDiff<T> {
    removed: T[];
    new: T[];
}

export interface PatientsExaminations {
    patients: string[];
    patientsExaminations: object;
}

export interface DetailSpExamination<T> {
    key: string;
    dataSource: MatTableDataSource<T>;
    foundDicomImages?: CheckDicomImageDto[];
}

export interface ResponseRequestOverviewSearch {
    searchString: string;
    data?: ResponseRequestOverview;
}

@Injectable({
    providedIn: 'root'
})
export class HelperService {

    requestOverviewData?: ResponseRequestOverviewSearch;

    public sessionPageSizeSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(20);
    public sessionPageSize$: Observable<number> = this.sessionPageSizeSubject$.asObservable();


    public sessionUnitSearchSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public sessionUnitSearch$: Observable<string> = this.sessionUnitSearchSubject$.asObservable();

    constructor() {}

    filterByUserData(availableArr?: SelectItem[], userDefault?: (UserSettingDetail | null | undefined)[] | null) {

        const filtered: SelectItem[] = [];
        for(const a of availableArr || []) {

            for(const d of userDefault || []) {
                if(a.item_id === d?.value) {
                    filtered.push({
                        item_id: a.item_id,
                        item_text: a.item_text,
                        item_type: a.item_type,
                        item_value: a.item_value,
                        db_id: d.id
                    });
                }
            }
        }

        return filtered.sort((a, b) => (filtered?.map(x => x.item_id).indexOf(a.item_id) || 0) - (filtered?.map(x => x.item_id).indexOf(b.item_id) || 0)) || [];
    }

    filterUserData(availableArr?: SelectItem[], userDefaultArr?: (string | null | undefined)[] | null): SelectItem[] {
        return availableArr?.filter(x =>
            userDefaultArr?.includes(x.item_id)).sort((a, b) => (userDefaultArr?.indexOf(a.item_id) || 0) - (userDefaultArr?.indexOf(b.item_id) || 0)) || [];
    }

    getDiffs<T>(origArr?: T[], selectedItems?: SelectItem[], idKeyName: string = 'value', metaNewObject?: T): MultiselectDiff<T> {

        if(!origArr || !selectedItems)
        {return {new: [], removed: []};}

        // @ts-ignore
        const removed = origArr.filter(item => item[idKeyName]?.toString() && selectedItems.map(x => x.item_id).indexOf(item[idKeyName].toString()) < 0);

        // @ts-ignore
        const newSelectedItems= selectedItems.filter(s => origArr.map(x => x[idKeyName].toString()).indexOf(s.item_id) < 0);

        const newSelectedItemsMapped: T[] = [];
        for(const n of newSelectedItems) {
            const data: any = {};
            data[idKeyName] = Number(n.item_id);
            newSelectedItemsMapped.push(Object.assign(data, metaNewObject));
        }

        return {
            new: newSelectedItemsMapped,
            removed
        };

    }

    isValidHsaId( string?: string ): boolean {

        if( !string )
        {return false;}

        const origString = string.toLowerCase();

        return origString.length > 12 && origString.slice(0,2) === 'se' && this.isNumber(origString.slice(2,12));

    }

    makeListDistinct<T>(data: any[], distinctBy: string): T[] {
        return [...new Map(data.map(item => [item[distinctBy], item])).values()] as T[];
    }

    getPatientsExaminations(examinations: SpExaminationDetailsSearch[] | SpExaminationsSent[] | PriorExaminationsDto[]): PatientsExaminations {

        // @ts-ignore
        const foundPatients = examinations.reduce(function(r, a) {
            // @ts-ignore
            r[a.patientId] = r[a.patientId] || [];
            // @ts-ignore
            r[a.patientId].push(a);
            return r;
        }, Object.create(null));

        const patArr = Object.keys(foundPatients);

        return {
            patients: patArr,
            patientsExaminations: foundPatients
        };

    }


    updateOrPushToArr<T>(obj: DetailSpExamination<T>, arr: { key: string; dataSource: MatTableDataSource<T> }[]): { key: string; dataSource: MatTableDataSource<T> }[] {
        const objIndex = arr.findIndex(inArray => inArray.key === obj.key);
        if (objIndex > -1) {
            arr[objIndex] = obj;
        } else {
            arr.push(obj);
        }
        return arr;
    }


    parseDicomGroups(selectedGroups: SelectItem[], eaDicomGroups: EaDicomGroupDto[]): DicomGroupDto[] {
        const foundSelectedGroups: DicomGroupDto[] = [];

        selectedGroups.forEach(item => {
            const foundGroup = eaDicomGroups.find(x =>  x.name+':'+x.archiveName === item.item_id);

            if(foundGroup) {
                foundSelectedGroups.push({
                    name: foundGroup.name,
                    archiveName: foundGroup.archiveName,
                    claims: foundGroup.claims?.map(x => ({
                        claimType: x.claimType,
                        resource: x.resource
                    } as DicomGroupClaimDto)),
                    members: foundGroup.members,
                    everyone: foundGroup.everyone
                });
            }

        });

        return foundSelectedGroups;
    }

    mapDicomGroupsToSelectedItems(dicomGroups?: EaDicomGroupDto[] | DicomGroupDto[]): SelectItem[] {
        return dicomGroups?.map(x => ({
            item_id: x.name+':'+x.archiveName,
            item_text: x.name+':'+x.archiveName,
            item_tooltip_text: x.claims?.map(x => x.claimType).join(', ')
        })) ?? [];
    }

    isNumber(value?: string | number): boolean
    {
        return ((value !== null) &&
      (value !== '') &&
      !isNaN(Number(value?.toString())));
    }

    parseBirthDateFromPatientId(patientId: string | undefined): string {

        if(!patientId)
        {return '';}

        if(patientId.length === 12) {
            return `${patientId.substring(0,4)}-${patientId.substring(4,6)}-${patientId.substring(6,8)}`;
        } else if(patientId.length === 10) {
            return `${patientId.substring(0,2)}-${patientId.substring(2,4)}-${patientId.substring(4,6)}`;
        }
        return '';

    }

    getFormattedFileSize(bytes?: number, decimals: number = 2): string {
        if (!bytes || !+bytes) {return '0 Bytes';}

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;

    }

    getFormattedDateAsString(theDate: Date): string {
        return theDate.getFullYear() +
          ('0' + (theDate.getMonth()+1)).slice(-2) +
          ('0' + theDate.getDate()).slice(-2);
    }

    static getDateAsString(date: Date): string {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    static getValidatedDateString(dateString?: string): string | undefined {
        const allCharactersAreNumbers = (s: string): boolean => {
            for (const c of s) {
                if (Number.isNaN(Number.parseInt(c))) {
                    return false;
                }
            }
            return true;
        }
        if (!dateString) {
            return undefined;
        }
        const dateSplit = dateString.split('-');
        // There must be three segments in a valid date string: year, month and date.
        if (!(dateSplit.length === 3 &&
            dateSplit[0].length === 4 &&
            (dateSplit[1].length === 1 || dateSplit[1].length === 2) &&
            (dateSplit[2].length === 1 || dateSplit[2].length === 2))
        ) {
            return undefined;
        }
        // Validate year by checking that it consists of four numbers.
        if (!allCharactersAreNumbers(dateSplit[0])) {
            return undefined;
        }
        
        // Validate month by checking that it consists of 1 or 2 numbers.
        if (!allCharactersAreNumbers(dateSplit[1])) {
            return undefined;
        }
        const monthAsNumber: number = Number.parseInt(dateSplit[1]);
        if (monthAsNumber < 1 || monthAsNumber > 12) {
            return undefined;
        }

        // Validate date by checking that it consists of 1 or 2 numbers between 1 and 31.
        if (!allCharactersAreNumbers(dateSplit[2])) {
            return undefined;
        }
        const dateAsNumber: number = Number.parseInt(dateSplit[2]);
        if (dateAsNumber < 1 || dateAsNumber > 31) {
            return undefined;
        }
        // Date string is valid. Now convert to a Date object and back to a string to
        // handle combinations of year/month/date.
        return this.getDateAsString(new Date(dateString));
    }
    getFromBetween = {
        results:[''],
        stringToCheck:'',
        getFromBetween(sub1: string,sub2: string): string | undefined {
            if(this.stringToCheck.indexOf(sub1) < 0 || this.stringToCheck.indexOf(sub2) < 0) {return undefined;}
            const SP = this.stringToCheck.indexOf(sub1)+sub1.length;
            const string1 = this.stringToCheck.substr(0,SP);
            const string2 = this.stringToCheck.substr(SP);
            const TP = string1.length + string2.indexOf(sub2);
            return this.stringToCheck.substring(SP,TP);
        },
        removeFromBetween(sub1: string,sub2: string): void {
            if(this.stringToCheck.indexOf(sub1) < 0 || this.stringToCheck.indexOf(sub2) < 0) {return;}
            const removal = sub1+(this.getFromBetween(sub1,sub2) ?? '')+sub2;
            this.stringToCheck = this.stringToCheck.replace(removal,'');
        },
        getAllResults(sub1: string,sub2: string): void {
            // first check to see if we do have both substrings
            if(this.stringToCheck.indexOf(sub1) < 0 || this.stringToCheck.indexOf(sub2) < 0) {return;}

            // find one result
            const result = this.getFromBetween(sub1,sub2);
            // push it to the results array
            if(result) {
                this.results.push(result);
            }
            // remove the most recently found one from the string
            this.removeFromBetween(sub1,sub2);

            // if there's more substrings
            if(this.stringToCheck.indexOf(sub1) > -1 && this.stringToCheck.indexOf(sub2) > -1) {
                this.getAllResults(sub1,sub2);
            }
            else {return;}
        },
        get(sub1: string, sub2: string, stringToCheck?: string) {
            this.results = [];

            if(!stringToCheck)
            {return [];}

            this.stringToCheck = stringToCheck;
            this.getAllResults(sub1,sub2);
            return this.results;
        }
    };

}
