import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {AppBlankComponent} from './layouts/blank/blank.component';
import {HomeComponent} from './pages/shared/home/home.component';
import {ProfileComponent} from './pages/shared/profile/profile.component';
import {ExportsDownloadComponent} from './pages/admin-portal/exports/download/exports-download.component';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {SettingsComponent} from './pages/shared/settings/settings.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: 'home', // '/collaboration-portal/work-list',
                pathMatch: 'full',
            },
            {
                path: 'home',
                component: HomeComponent,
                data: {
                    title: 'Home'
                },
            },
            {
                path: 'exports',
                component: ExportsDownloadComponent,
                data: {
                    title: 'Download Export',
                    urls: [{ title: 'Exports' }],
                },
            }
        ]
    },
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: '/collaboration-portal/work-list',
                pathMatch: 'full',
            },
            {
                path: 'profile',
                component: ProfileComponent,
                data: {
                    title: 'Profile',
                    urls: [{title: 'Home', url: '/home'}, {title: 'Profile'}],
                },
            },
            {
                path: 'settings',
                component: SettingsComponent,
                data: {
                    title: 'Settings',
                    urls: [{title: 'Home', url: '/home'}, {title: 'Settings'}],
                },
            },
            {
                path: 'admin-portal',
                loadChildren: () =>
                    import('./pages/admin-portal/admin-portal.module').then((m) => m.AdminPortalModule),
            },
            {
                path: 'collaboration-portal',
                loadChildren: () =>
                    import('./pages/collaboration-portal/collaboration-portal.module').then((m) => m.CollaborationPortalModule),
            }
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'error',
                loadChildren: () =>
                    import('./pages/shared/errors/errors.module').then((m) => m.ErrorsModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'error/404',
    },
];
