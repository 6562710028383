
<p-multiSelect class="cgi-multiselect-drop"
               [options]="dataValue | orderBy:'item_text'"
               [dataKey]="'item_id'"
               [(ngModel)]="selectedItems"
               (ngModelChange)="selectedItemsChange.emit(selectedItems)"
               placeholder="{{placeholderText}}"
               optionLabel="item_text"
               display="chip">
  <ng-template let-value pTemplate="selectedItems">

    <mat-chip-listbox aria-label="selectedItems" cdkDropListGroup>
      <div cdkDropList
          (cdkDropListDropped)="drop($event)"
          [cdkDropListData]="i"
          *ngFor="let option of selectedItems;let i=index">
        <mat-chip cdkDrag (removed)="remove(option)">
          {{option.item_text}}
          <button matChipRemove (click)="selectedItemsChange.emit(selectedItems)" [attr.aria-label]="'remove ' + option.item_text">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </div>
    </mat-chip-listbox>

    <div *ngIf="!selectedItems || selectedItems.length === 0">{{placeholderText}}</div>
  </ng-template>


</p-multiSelect>

