@for (toast of toastsToShow; track toast) {
    <div
        id="toast-{{toast.id}}"
        class="custom-toast"
        [ngClass]="{'custom-toast-success': toast.type === 'success', 'custom-toast-error': toast.type === 'error'}"
    >
      <div class="custom-toast-body">{{ toast.message }}</div>
      <button mat-button color="primary" *ngIf="toast.action" (click)="remove(toast)" class="custom-toast-action">{{ toast.action }}</button>
    </div>
}
