import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    Renderer2,
    ViewChild
} from '@angular/core';
import {MenuItems} from '../../shared/items/menu-items';
import {UserService} from '../../shared/services/user.service';
import {User, UserSettingDetail} from '../../shared/models/user.model';
import {Direction} from '@angular/cdk/bidi';
import {MatSidenav} from '@angular/material/sidenav';
import {ApiService} from '../../shared/services/api.service';
import {Theme, StateService} from 'src/app/shared/services/state.service';
import {Subscription} from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';
import {environment} from '../../../environments/environment';

/** @title Responsive sidenav */
@Component({
    selector: 'app-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: [],
})
export class FullComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
    mobileQuery: MediaQueryList;
    dir: Direction = 'ltr';
    dark = false;
    minisidebar = false;
    boxed = false;
    horizontal = false;
    green = false;
    blue = false;
    danger = false;
    showHide = false;
    url = '';
    sidebarOpened = false;
    status = false;
    authedUser: User | undefined;
    isSideBarOpenedOnce = false;
    isLoading = false;

    @ViewChild('snav') sideNavEl?: MatSidenav;
    public showSearch = false;
    // tslint:disable-next-line - Disables all
    private readonly _mobileQueryListener: () => void;

    private themeChangedSubscription: Subscription;

    private readonly LIGHT_THEME_CLASS_NAME: string = 'admin-portal-light-theme';
    private readonly DARK_THEME_CLASS_NAME: string = 'admin-portal-dark-theme';

    constructor(
        private cdRef: ChangeDetectorRef,
        private router: Router,
        private media: MediaMatcher,
        private _userService: UserService,
        private menuItems: MenuItems,
        private _apiService: ApiService,
        private _stateService: StateService,
        private renderer: Renderer2,
        private _toastService: ToastService
    ) {
        this.mobileQuery = this.media.matchMedia('(min-width: 1100px)');
        this._mobileQueryListener = () => this.cdRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
        // this is for dark theme
        // const body = document.getElementsByTagName('body')[0];
        // body.classList.toggle('dark');
        this.dark = false;

        this.themeChangedSubscription = this._stateService.themeChanged$.subscribe(theme => {
            if (theme === Theme.Light) {
                this.renderer.addClass(document.body, this.LIGHT_THEME_CLASS_NAME);
                this.renderer.removeClass(document.body, this.DARK_THEME_CLASS_NAME);
            } else if (theme === Theme.Dark) {
                this.renderer.removeClass(document.body, this.LIGHT_THEME_CLASS_NAME);
                this.renderer.addClass(document.body, this.DARK_THEME_CLASS_NAME);
            }
        });

        // Check if theme setting exists locally, otherwise default to light theme.
        const maybeLocalThemeSetting = localStorage.getItem(this._stateService.THEME_SETTING_LOCAL_STORAGE_KEY);
        if (maybeLocalThemeSetting === Theme.Light) {
            this._stateService.changeTheme(Theme.Light);
        } else if (maybeLocalThemeSetting === Theme.Dark) {
            this._stateService.changeTheme(Theme.Dark);
        } else {
            this._stateService.changeTheme(Theme.Light);
        }

        this._apiService.isLoadingArr$.subscribe(x => {
            this.isLoading = x.length > 0;
        });

        this._userService.listenToAuthedUser().subscribe(x => {
            this.authedUser = x;
            this.loadThemeSettings();
            if (this.authedUser) {
                this.maybeCloseNavMenuByConfig();
            }
        });

        router.events.subscribe((val) => {
            this.maybeCloseNavMenuByConfig();
        });
    }

    ngAfterViewInit(): void {
        this._userService.listenToAuthedUser().subscribe(x => {

            setTimeout(() => {

                if(!this.authedUser) {
                    void this.sideNavEl?.close();
                } else if(!this.isSideBarOpenedOnce && !this._userService.getUserBooleanSetting('is_always_close_nav_menu')) {
                    this.isSideBarOpenedOnce = true;
                    void this.sideNavEl?.open();
                }
            }, 200);

            console.log('this.authedUsersideNavEl', this.authedUser);
        });

        const textZoomLevel = (((window.outerWidth) / window.innerWidth) * 100) / 100;
        const pinchZoomLevel = window.visualViewport?.scale;
        console.log('pinchZoomLevel', pinchZoomLevel);
        console.log('textZoomLevel', textZoomLevel);

        if(textZoomLevel > 1.5) {
            this._toastService.add({
                message: 'You have a zoom bigger then 150%. Please try to zoom out in your browser to get a better user experience. Hold CTRL and pres -'
            });
        }

    }

    ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.themeChangedSubscription.unsubscribe();
    }

    ngAfterViewChecked(): void
    {
        this.cdRef.detectChanges();
    }

    clickEvent(): void {
        this.status = !this.status;
    }

    darkClick(): void {
    // const body = document.getElementsByTagName('body')[0];
    // this.dark = this.dark;
        const body = document.getElementsByTagName('body')[0];
        body.classList.toggle('dark');
    // if (this.dark)
    // else
    // 	body.classList.remove('dark');
    // this.dark = this.dark;
    // body.classList.toggle('dark');
    // this.dark = this.dark;
    }

    private loadThemeSettings(): void {
        const userDetails: UserSettingDetail[] | undefined = this.authedUser?.userSettings?.userSettingDetails;
        const maybeThemeSettings = userDetails?.filter(x => x.type === 'default_color_theme');
        if (maybeThemeSettings && maybeThemeSettings.length) {
            let settingToUse: UserSettingDetail = maybeThemeSettings[0];
            if (maybeThemeSettings.length > 1) {
                maybeThemeSettings.slice(1, maybeThemeSettings.length).forEach(s => {
                    if ((s.id || -1) > (settingToUse.id || -1)) {
                        settingToUse = s;
                    }
                });
            }
            if (settingToUse && settingToUse.value) {
                if (settingToUse.value === Theme.Light) {
                    this._stateService.changeTheme(Theme.Light);
                } else if (settingToUse.value === Theme.Dark) {
                    this._stateService.changeTheme(Theme.Dark);
                }
            }
        }
    }

    private maybeCloseNavMenuByConfig() {
        const userIsAlwaysCloseNavMenu = this._userService.getUserBooleanSetting('is_always_close_nav_menu');
        if (userIsAlwaysCloseNavMenu) {
            void this.sideNavEl?.close();
        }
    }

    onNotify(value: boolean) {
        if (window.innerWidth < 1024) {
            this.sideNavEl?.close();
        }
    }

    isTest(): boolean {
        return !environment.production;
    }
}
