<!-- Breadcrumb -->
<mat-card class="m-0">
  <div class="page-breadcrumb p-t-20 p-b-20 p-l-30 p-r-30">
    <div class="page-container">
      <div class="breadcrumb-container">
        <div>
          <h4 class="page-title m-0">{{ pageInfo?.title | translate }}</h4>
        </div>
        <div>
          <div class="breadcrumb d-flex">
            <ul class="list-style-none d-flex align-items-center">
              <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                  <a mat-button color="primary" [routerLink]="url.url">{{ url.title | translate }}</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="last">{{ url.title | translate }}</li>
              </ng-template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
