<mat-card appearance="outlined" class="cgi-card">
  <mat-card-content>

    <h2>{{'Current Active Host' | translate}}</h2>

    <div fxLayout="row wrap" class="align-items-center" fxLayoutGap="16px grid" fxLayoutAlign="space-around start">
      <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
        <mat-form-field *ngIf="currentActiveHost"  class="reference-data-input-field" appearance="outline">
          <mat-label>{{'Current Active Host' | translate}}</mat-label>
          <div style="display: flex">
            <input matInput [(ngModel)]="currentActiveHost.key" type="text" placeholder="{{'Set current active host' | translate}}">
            <button mat-mini-fab color="primary" (click)="determineUpdateCurrentActiveHost()">
              <mat-icon>save</mat-icon>
            </button>
            <button *ngIf="currentActiveHostFound" class="m-l-10" mat-mini-fab color="warn" (click)="deleteCurrentActiveHost()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-form-field>
      </div>
      <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
        <p>{{'This determines the active node in a cluster setup. E.g. what node to do the Statistics queue jobs' | translate}}</p>
      </div>
    </div>



  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="cgi-card">
  <mat-card-content>

    <h2>{{'Background Workers' | translate}}</h2>

    <div fxLayout="row wrap" class="align-items-center" fxLayoutGap="16px grid">
      <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
        <mat-form-field  class="reference-data-input-field" appearance="outline">
          <mat-label>{{'Search' | translate}}</mat-label>
          <div style="display: flex">
            <input matInput [(ngModel)]="searchString" (keyup.enter)="doSearch()" type="text" placeholder="{{'Search' | translate}}">
            <button mat-mini-fab color="primary" (click)="doSearch()">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </mat-form-field>
      </div>
      <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
          <div>
              <button mat-raised-button class="m-b-10" [disabled]="searchString === ''" (click)="searchString = ''">{{'Clear fields' | translate}}</button>

              <button mat-raised-button color="primary" class="m-l-10 m-b-10" (click)="isShowAllColumns = !isShowAllColumns">{{'Toggle all columns' | translate}}</button>

              <button mat-mini-fab color="primary" class="m-l-10 m-b-10" (click)="loadBackgroundJobsData()" matTooltipPosition="above" matTooltip="{{'Refresh the list' | translate}}">
                  <mat-icon>sync</mat-icon>
              </button>
          </div>

          <div>
              <button mat-raised-button color="warn" class="m-b-10" (click)="setAllRegularJobsToInactive()">{{'Sett all regular to Inactive' | translate}}</button>
              <button mat-raised-button color="primary" class="m-l-10 m-b-10" (click)="setAllRegularJobsToActive()">{{'Sett all regular to Active' | translate}}</button>
          </div>

      </div>
    </div>

    <div class="admin-portal-table-container">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)">


        <ng-container *ngFor="let column of getColumns(); index as i;" matColumnDef="{{column}}">

            <div *ngIf="(column === 'modify'); else check1_content">
                <th mat-header-cell *matHeaderCellDef><!--{{'modify' | translate}}--></th>
                <td class="text-small" mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="primary" (click)="updateBackgroundJob(element)">
                        <mat-icon>edit_note</mat-icon>
                    </button>
                </td>
            </div>

            <ng-template #check1_content >
              <div *ngIf="(column === 'isActive'); else check2_content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="{{'Sort' | translate}}">{{column | translate | toFirstUpperCase}}</th>
                <td class="text-small" mat-cell *matCellDef="let element"><mat-icon *ngIf="element[column]" class="text-success">check</mat-icon> <mat-icon *ngIf="!element[column]" class="text-danger">close</mat-icon></td>
              </div>
            </ng-template>

            <ng-template #check2_content >
                <div *ngIf="(column === 'isSetToInactive'); else check3_content">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="{{'Sort' | translate}}">{{column | translate | toFirstUpperCase}}</th>
                    <td class="text-small" mat-cell *matCellDef="let element"><mat-icon *ngIf="element[column]" class="text-danger">close</mat-icon></td>
                </div>
            </ng-template>

          <ng-template #check3_content>
            <div *ngIf="(column === 'created' ||  column === 'updated'); else check4_content">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="{{'Sort' | translate}}">{{column | translate | toFirstUpperCase}}</th>
              <td class="text-small" mat-cell *matCellDef="let element">{{element[column] | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
            </div>
          </ng-template>

          <ng-template #check4_content>
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="{{'Sort' | translate}}">{{column | translate | toFirstUpperCase}}</th>
            <td class="text-small" mat-cell *matCellDef="let element">{{element[column]}}</td>
          </ng-template>

        </ng-container>


        <tr mat-header-row *matHeaderRowDef="getColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getColumns();"></tr>
      </table>
    </div>

    <!--
    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"
                   showFirstLastButtons (page)="pageChanged($event)"
                   attr.aria-label="{{'Select page of periodic elements' | translate}}">
    </mat-paginator>
    -->
    <app-cgi-mat-paginator (pageChange)="pageChanged($event)"></app-cgi-mat-paginator>


  </mat-card-content>
</mat-card>


