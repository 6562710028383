import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BackgroundJob} from '../../../../shared/models/statistics.model';

@Component({
    selector: 'app-edit-background-job-dialog',
    templateUrl: 'edit-background-job-dialog.component.html',
    styleUrls: ['edit-background-job-dialog.component.scss'],
})
export class EditBackgroundJobDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<EditBackgroundJobDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: BackgroundJob,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}
