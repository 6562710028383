import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {User} from '../../../shared/models/user.model';
import {ApiService} from '../../../shared/services/api.service';
import {UserService} from '../../../shared/services/user.service';
import {CgiMatPaginatorComponent} from '../../../shared/component/cgi-mat-pagination/cgi-mat-paginator.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import {
    BackgroundJob, ResponseBackgroundJobs
} from '../../../shared/models/statistics.model';
import {Subscription} from 'rxjs';
import {ResponseSharedMetas, SharedMetaItem} from '../../../shared/models/shared.model';
import {ConfirmDialogComponent} from '../../../shared/dialog/confirm-dialog/confirm-send-dialog.component';
import {EditBackgroundJobDialogComponent} from './edit-background-job-dialog/edit-background-job-dialog.component';

@Component({
    selector: 'app-statistics',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    allBackgroundJobColumns: string[] = ['modify', 'id', 'identity', 'runtimeId', 'name', 'type', 'machineName', 'isActive', 'queueCount', 'created', 'updated', 'status', 'taskName', 'taskType', 'isSetToInactive'];

    displayedBackgroundJobColumns: string[] = ['modify', 'name', 'type', 'machineName', 'isActive', 'queueCount', 'created', 'updated', 'status', 'taskName', 'taskType', 'isSetToInactive'];

    isShowAllColumns = false;

    dataSource = new MatTableDataSource<BackgroundJob>();

    authedUser: User | undefined;

    currentActiveHostFound = false;

    currentActiveHost?: SharedMetaItem;

    searchString = '';

    disposable?: Subscription;

    trans: any;
    @ViewChild(CgiMatPaginatorComponent) paginator?: CgiMatPaginatorComponent;

    @ViewChild(MatSort) sort?: MatSort;


    constructor(private _cdr: ChangeDetectorRef,
                private _apiService: ApiService,
                private _dialog: MatDialog,
                private _translate: TranslateService,
                private _userService: UserService,
                private _route: ActivatedRoute,
                private _toastService: ToastService) {
    }

    ngOnInit(): void {

        this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
            this.authedUser = x;

            if(!this.authedUser?.userId)
            {return;}

            this.loadBackgroundJobsData();

            this.loadCurrentActiveHost();

        });

        // @ts-ignore
        this.dataSource.paginator = this.paginator;
        // @ts-ignore
        this.dataSource.sort = this.sort;

        this.currentActiveHost ??= {
            id: 0,
            value: '',
            key: '',
            text: '',
            section: 'not_set',
            type: 'current_active_host',
            disabled: false
        };

    }

    getColumns(): string[] {
        return this.isShowAllColumns ? this.allBackgroundJobColumns : this.displayedBackgroundJobColumns;
    }

    loadBackgroundJobsData(): void {
        this._cdr.detectChanges();

        let skip = 0;
        const pageIndex = this.paginator?.pageIndex;
        if (this.paginator?.pageSize !== undefined && pageIndex !== undefined)
        {skip = this.paginator?.pageSize * pageIndex;}

        let uri = `/statistics/background_jobs?startPos=${skip}&nrItems=${this.paginator?.pageSize}`;

        if(this.sort?.direction !== '' && this.sort?.active && this.sort?.active !== '')
        {uri = `${uri}&sortName=${this.sort?.active}&sortOrder=${this.sort?.direction}`;}

        if(this.searchString && this.searchString !== '')
        {uri = `${uri}&searchString=${this.searchString}`;}

        this._apiService.get<ResponseBackgroundJobs>(uri).subscribe({
            next: (res) => {

                console.log('res.data', res.data);

                this.dataSource.data = res.data.items ?? [];

                if (this.paginator)
                {this.paginator.setLengthAndIndex(pageIndex, res?.data?.totalNr);}


            },
            error: (error: any) => {
                console.log('error examinations', error);
            }});

    }

    pageChanged(event: PageEvent): void {
        console.log({event});
        this.loadBackgroundJobsData();
    }

    sortChange(sortState: Sort): void {
        console.log({sortState});
        this.loadBackgroundJobsData();
    }

    doSearch(): void {
        if(this.paginator)
        {this.paginator.pageIndex = 0;}

        this.loadBackgroundJobsData();
    }

    ngOnDestroy(): void {
        this.disposable?.unsubscribe();
    }

    private loadCurrentActiveHost() {

        this._apiService.get('/shared_data?type=current_active_host').subscribe({
            next: res => {
                const data = res.data as ResponseSharedMetas;
                this.currentActiveHost = data.items[0];

                if(this.currentActiveHost?.id && this.currentActiveHost.id > 0) {
                    this.currentActiveHostFound = true;
                }

                this.currentActiveHost ??= {
                    id: 0,
                    value: '',
                    key: '',
                    text: '',
                    section: 'not_set',
                    type: 'current_active_host',
                    disabled: false
                };

            }}
        );

    }

    deleteCurrentActiveHost(): void {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: 'Are you sure you want to delete',
                identifier: this.currentActiveHost?.id
            }});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                if(!okSend || okSend !== 'ok' || !this.currentActiveHost?.id)
                {return;}

                this._apiService.delete('/shared_data/' + this.currentActiveHost?.id).subscribe(next => {
                    this._toastService.add({message: this.currentActiveHost?.id + ' was deleted'});
                    this.currentActiveHost = undefined;
                    this.currentActiveHostFound = false;
                });

            },
            error: (error) => console.log('dialog error', error)});
    }

    determineUpdateCurrentActiveHost(): void {

        if(this.currentActiveHostFound) {
            this.updateCurrentActiveHost();
        } else {
            this.createCurrentActiveHost();
        }

    }

    updateCurrentActiveHost(): void {

        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: 'Are you sure you want to update to',
                identifier: this.currentActiveHost?.key
            }});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                if(!okSend || okSend !== 'ok' || !this.currentActiveHost?.id)
                {return;}

                this._apiService.put('/shared_data', this.currentActiveHost).subscribe(next => {
                    this._toastService.add({message: this.currentActiveHost?.id + ' was updated'});
                });

            },
            error: (error) => console.log('dialog error', error)});
    }


    createCurrentActiveHost(): void {

        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: 'Are you sure you want to create',
                identifier: this.currentActiveHost?.key
            }});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                console.log('this.sharedMeta', this.currentActiveHost);

                if(!okSend || okSend !== 'ok' || !this.currentActiveHost)
                {return;}

                const uri = '/shared_data';

                this._apiService.post<SharedMetaItem>(uri, this.currentActiveHost).subscribe(next => {
                    this._toastService.add({message: next.data.id + ' - Shared data was created'});

                    this.currentActiveHost = next.data;

                    if(this.currentActiveHost?.id && this.currentActiveHost.id > 0) {
                        this.currentActiveHostFound = true;
                    }

                });

            },
            error: (error) => console.log('dialog error', error)});
    }


    updateBackgroundJob(item: BackgroundJob): void {
        const dialogRef = this._dialog.open(EditBackgroundJobDialogComponent, {
            data: item});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                if(!okSend || okSend.backgroundJob === undefined)
                {return;}

                const data = okSend.backgroundJob as BackgroundJob;

                const dialogRef2 = this._dialog.open(ConfirmDialogComponent, {
                    data: {
                        message: 'Are you sure you want to update to',
                        identifier: data.name
                    }});

                dialogRef2.afterClosed().subscribe({
                    next : (okSend2) => {

                        if(!okSend2 || okSend2 !== 'ok' || !data?.id)
                        {return;}

                        this._apiService.put<BackgroundJob>('/statistics/background_jobs', data).subscribe(next => {
                            this._toastService.add({message: next.data?.id + ' was updated'});
                            this.loadBackgroundJobsData();
                        });

                    },
                    error: (error) => console.log('dialog error', error)});

            },
            error: (error) => console.log('dialog error', error)});
    }

    setAllRegularJobsToInactive(): void {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: 'Are you sure you want to set all regular jobs to inactive'
            }});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                if(!okSend || okSend !== 'ok')
                {return;}

                const uri = '/statistics/background_jobs/all_regular_inactive';
                this._apiService.put<BackgroundJob[]>(uri).subscribe(next => {
                    this._toastService.add({message: 'Regular jobs was set to inactive'});
                    this.loadBackgroundJobsData();
                });

            },
            error: (error) => console.log('dialog error', error)});
    }

    setAllRegularJobsToActive(): void {
        const dialogRef = this._dialog.open(ConfirmDialogComponent, {
            data: {
                message: 'Are you sure you want to set all regular jobs to active'
            }});

        dialogRef.afterClosed().subscribe({
            next : (okSend) => {

                if(!okSend || okSend !== 'ok')
                {return;}

                const uri = '/statistics/background_jobs/all_regular_active';
                this._apiService.put<BackgroundJob[]>(uri).subscribe(next => {
                    this._toastService.add({message: 'Regular jobs was set to active'});
                    this.loadBackgroundJobsData();
                });

            },
            error: (error) => console.log('dialog error', error)});
    }
}
