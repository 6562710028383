// This file can be replaced during builds by using the `fileReplacements` array.
// `ng builds --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiBaseUrl: 'https://api.samtj.cgikna.se/cgikna/adminportal/1.0.0',
    oidcIssuer: 'https://auth.cgikna.se/realms/gitlabs',
    ivJumpOutUrl: 'https://bfr-test.vgregion.se/saml/login?patientId={PatientId}',
    zfpJumpOutUrl: 'https://zfp-test.bfr.vgregion/ZFP?mode=proxy&amp;lights=off&amp;titlebar=on#View&sui={StudyInstanceUID}',
    rpaIntegrationHsaIds: 'SE2321000131-E000000015694,SE2321000131-E000000009441,SE2321000131-E000000009940,SE2321000131-E000000016001',
    pathToManualSv: '/assets/documents/AdminPortal-UserManual-SWE.pdf',
    pathToManualEn: '/assets/documents/AdminPortal-UserManual-ENG.pdf',
    isPacsInstallation: false,
    dicomImport: {
        defaultPrefix: 'import_',
        importServerFolder: '\\testfolder',
        generateInstance: {
            iso: '1',
            ansiMember: '3',
            countryCode: '752',
            organization: '2321000131',
            manufacturerType: '1',
            manufacturerSerial: '1'
        }
    },
    errorPortal: {
        checkSwedishPid: true
    }
};

// 'https://api.samtj.cgikna.se/cgikna/adminportal/1.0.0'
// 'https://localhost:7048/cgikna/adminportal/1.0.0'
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
