import {Pipe, PipeTransform} from '@angular/core';
import {filter} from 'lodash';

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(array: any, search: string): any[] {

        return filter(array, search);
    }
}
