<h3 mat-dialog-title *ngIf="detail">{{'Details' | translate}}</h3>
<div mat-dialog-content *ngIf="detail">

    <mat-card appearance="outlined" class="cgi-card">
      <mat-card-content>
        <pre>{{detail | json}}</pre>
      </mat-card-content>
    </mat-card>

</div>

<div mat-dialog-actions class="buttons">
  <button mat-raised-button mat-dialog-close>{{'Close' | translate}}</button>
</div>

