import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SelectItem} from '../../models/select-item.model';
import {MultiSelect, MultiSelectSelectAllChangeEvent} from 'primeng/multiselect';
import {CgiMatPaginatorComponent} from '../cgi-mat-pagination/cgi-mat-paginator.component';

@Component({
    selector: 'app-cgi-multiselect',
    templateUrl: './cgi-multiselect.component.html',
    styleUrls: ['./cgi-multiselect.component.scss'],
})
export class CgiMultiselectComponent {

    /**
     * Holds the current value of the slider
     */
    @Input() placeholderText = '';

    /**
     * Holds the current value of the slider
     */
    @Input() isDropdownDisabled = false;

    /**
     * Holds the current value of the slider
     */
    @Input() isClearOnSelectAll = false;

    /**
     * Invoked when the model has been changed
     */
    @Output() isDropdownDisabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();


    /**
     * Holds the current value of the slider
     */
    @Input() dataValue: SelectItem[] = [];

    /**
     * Invoked when the model has been changed
     */
    @Output() dataValueChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();

    /**
     * Holds the current value of the slider
     */
    @Input() selectedItems: SelectItem[] = [];

    /**
     * Invoked when the model has been changed
     */
    @Output() selectedItemsChange: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();

    /*
    overlayOptions: OverlayOptions = {
        responsive: {direction: 'bottom'}
    };
    */

    @ViewChild('multiselect') multiSelect?: MultiSelect;

    // dropdownSettings?: IDropdownSettings;

    isUpdate = false;

    constructor(private cdr: ChangeDetectorRef) {
    }


    remove(fruit: SelectItem): void {
        const index = this.selectedItems.indexOf(fruit);

        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }
    }


    selectAllChange(event: MultiSelectSelectAllChangeEvent): void {

        console.log('selectAllChange',event );
        console.log('this.isClearOnSelectAll', this.isClearOnSelectAll );

        if(this.isClearOnSelectAll)
        {
            this.selectedItems = [];
            this.selectedItemsChange.emit(this.selectedItems);
        }

    }

    selectionChange(event: SelectItem[]): void {

        if(this.selectedItems.length === this.dataValue.length && this.isClearOnSelectAll) {
            this.selectedItems = [];
            this.selectedItemsChange.emit(this.selectedItems);
        } else {
            this.selectedItemsChange.emit(this.selectedItems);
        }

        if(this.isClearOnSelectAll)
        {this.multiSelect?.hide();}

    }
}
