<h1 mat-dialog-title>{{'Update Background Job' | translate}}</h1>
<div mat-dialog-content>

  <p><strong>{{ 'Please only update "IsSetToInactive" for the job to automatically change status by DB Updater' | translate }}</strong></p>

  <mat-form-field class="reference-data-input-field" appearance="outline">
    <mat-label>{{'isSetToInactive' | translate | toFirstUpperCase}}</mat-label>
    <mat-select [(value)]="data.isSetToInactive" name="selectedSection" >
      <mat-option [value]="false">
        {{'False' | toYesNo | translate}}
      </mat-option>
      <mat-option [value]="true">
        {{'True' | toYesNo | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="reference-data-input-field" appearance="outline">
    <mat-label>{{'isActive' | translate | toFirstUpperCase}}</mat-label>
    <mat-select [(value)]="data.isActive" name="selectedSection" >
      <mat-option [value]="false">
        {{'False' | toYesNo | translate}}
      </mat-option>
      <mat-option [value]="true">
        {{'True' | toYesNo | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{backgroundJob: data}" cdkFocusInitial>{{'Update' | translate}}</button>
</div>
