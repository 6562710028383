import {debounceTime} from 'rxjs/operators';
import {Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild,} from '@angular/core';
import {FormControl, FormGroup, Validators,} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {MatSelect} from '@angular/material/select';
import {ApiService} from '../../services/api.service';
import {HelperService} from '../../services/helper.service';
import {UnitNode} from '../../../pages/admin-portal/reference-data/units/tree/units-tree.component';

@Component({
    selector: 'app-cgi-search-select',
    templateUrl: './cgi-search-select.component.html',
    styleUrls: ['./cgi-search-select.component.scss'],
})
export class CgiSearchSelectComponent
{
    // custom-field specific variables.
    @Input() nameFields: string[] = [];
    @Input() resParam = '';

    @Input() overrideLabel?: string = undefined;


    @Input() nrDefaultItems = 10;

    @Input() apiUri = '';
    @Input() isApiUrlWithParams: boolean = false;


    @Input() apiIdField = 'id';
    @Input() objIdField = 'id';

    /**
     * Holds the current value of the slider
     */
    @Input() startIndex: string | number = '';

    @Input() preDefinedNode?: Observable<UnitNode | undefined>;

    /**
     * Holds the current value of the slider
     */
    @Input() selectedItem: any;

    /**
     * Invoked when the model has been changed
     */
    @Output() selectedItemChange: EventEmitter<any> = new EventEmitter<any>();


    @ViewChild('itemListCtrl') itemListCtrl?: MatSelect;

    @ViewChild('keywordCtrlOption', { read: ElementRef, static:false }) keywordCtrlOption?: ElementRef;


    searchSelectForm: FormGroup;
    keyword: FormControl;
    itemList: FormControl;
    items: any[] = [];
    scrollLoading = false;
    isLastPage = false;
    nrPageNumber = 0;

    constructor(
        private _focusMonitor: FocusMonitor,
        private _elementRef: ElementRef<HTMLElement>,
        private injector: Injector,
        private _apiService: ApiService,
        private _helperService: HelperService
    ) {

        _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        });

        // input box
        this.keyword = new FormControl('', [
            // Validators.required,
            // Validators.minLength(3),
            Validators.maxLength(100),
        ]);

        // mat-select
        this.itemList = new FormControl();
        // this.itemList = this._controlName.control;

        // form
        this.searchSelectForm = new FormGroup({
            keyword: this.keyword,
            itemList: this.itemList,
        });

        this.itemList.valueChanges.subscribe((vl) => {
            console.log('arVl', vl);
        });
    }

    ngAfterViewInit(){
        this.keywordCtrlOption?.nativeElement.classList.remove('mdc-list-item--disabled');
    }

    ngOnInit(): void {
    // dynamically inject service for requested list;
    // call the backend on user input
        this.keyword.valueChanges
            .pipe(debounceTime(300))
        //  .pipe()
            .subscribe((value) => {
                if (this.keyword.valid) {
                    this.nrPageNumber = 0;
                    this.callApi(value);
                }
                if (!this.keyword.value) {
                    this.nrPageNumber = 0;
                    this.callApi();
                }
            });

        this.preDefinedNode?.subscribe(x => {
            // console.log("this.startIndex.toString() 2", this.startIndex.toString());
            if(x) {
                this.startIndex = x?.indexId ?? '';
                this.callApi('', 0, this.startIndex.toString());
            }
        });


        if(!this.preDefinedNode) {
            // console.log("this.startIndex.toString()", this.startIndex.toString());
            this.callApi('', 0, this.startIndex.toString());
        }
    // this.stateChanges.next();
    }


    bootstrap(event: any) {
    // @todo: focus the keyword input box.
        if (this.itemListCtrl?.panel) {
            this.itemListCtrl.panel.nativeElement.addEventListener(
                'scroll',
                (scrollEvent: any) => {
                    const scrollTop = Math.round(
                        this.itemListCtrl?.panel.nativeElement.scrollTop
                    );
                    const scrollHeight = this.itemListCtrl?.panel.nativeElement.scrollHeight;
                    const offsetHeight = this.itemListCtrl?.panel.nativeElement.offsetHeight;
                    if (scrollTop === scrollHeight - offsetHeight) {
                        if (!this.isLastPage) {
                            // scroll hit bottom.
                            this.nrPageNumber++;
                            this.callApi(this.keyword.value, this.nrPageNumber);
                            this.itemListCtrl?.panel.nativeElement.scrollTo(0, scrollHeight);
                        } else {
                            // end of search results.
                        }
                    }
                }
            );
        }
        // clear keyword field on close an reset;
        if (this.itemListCtrl?.panel && this.keyword.value) {
            this.keyword.reset();
        }
        this.stateChanges.next();
    }


    /**
     * make api call based on the type of dropdown.
     * @param searchString search keyword
     * @param pageNr pageNr number for handling scroll
     * @param append set true if function is called for for updating the list on scroll.
     */
    callApi(searchString = '', pageNr = 0, startIndex = '') {

        if(this.apiUri === '')
        {return false;}

        this.nrPageNumber = pageNr;

        this.scrollLoading = true;

        let uri = `${this.apiUri}${this.isApiUrlWithParams ? '&' : '?'}onlyActive=true&startPos=${this.nrPageNumber*this.nrDefaultItems}&nrItems=${this.nrDefaultItems}`;

        if(startIndex && startIndex !== '')
        {uri = `${uri}&${this.apiIdField}=${startIndex}`;}

        if(searchString && searchString !== '')
        {uri = `${uri}&searchString=${searchString}`;}

        this._apiService.get(uri).subscribe({
            next: (res: any) => {

                const parsedData = this.resParam !== '' ? res.data[this.resParam] : res.data;

                this.isLastPage = parsedData.length === 0;

                this.items = this.items.concat(parsedData);
                this.items = this.items.filter(cItem => parsedData.find((aItem: any) => cItem[this.objIdField] === aItem[this.objIdField]));

                if(startIndex && startIndex !== '' && parsedData.length > 0)
                {this.selectedItem = parsedData[0];}

                if(this.selectedItem)
                {this.items = [this.selectedItem].concat(this.items);}

                this.items = this._helperService.makeListDistinct(this.items, this.objIdField);

                this.scrollLoading = false;
            },
            error: (error: any) => {
                this.scrollLoading = false;
                console.log('error examinations', error);
            }});

    }

    compareFn(c1: any, c2: any): boolean {
        if (c1 && c2) {
            if (c1[this.objIdField] === c2[this.objIdField]) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }


    // prevent optionlist scroll on text input.
    preventScroll(event: any) {
        event.stopPropagation();
    }

    // mat-form-field specific properties. -- no modifications required.
    private focused = false;
    private stateChanges: Subject<void> = new Subject();
    static nextId = 0;


}
