
<!--<ng-multiselect-dropdown class="cgi-multiselect" *ngIf="!isUpdate"
                         [placeholder]="placeholderText"
                         [settings]="dropdownSettings"
                         [data]="dataValue | orderBy:'item_text'"
                         [(ngModel)]="selectedItems"
                         [disabled]="isDropdownDisabled"
                         (ngModelChange)="selectedItemsChange.emit(selectedItems)"
>
  <ng-template #optionSelectedTemplate let-option="option" let-id="id" >
    <div class="multi-dropdown-item">
      {{option}}
    </div>
  </ng-template>
</ng-multiselect-dropdown>-->

<p-multiSelect #multiselect class="cgi-multiselect"
               [options]="dataValue | orderBy:'item_text'"
               [(ngModel)]="selectedItems"
               (ngModelChange)="selectionChange($event)"
               [disabled]="isDropdownDisabled"
               [scrollHeight]="'300px'"
               [style]="{'width':'100%'}"
               placeholder="{{placeholderText}}"
               optionLabel="item_text"
               display="chip">
  <ng-template let-value pTemplate="selectedItems">

    <mat-chip-listbox #chipGrid aria-label="selectedItems">
      <mat-chip *ngFor="let option of selectedItems;let i=index" (removed)="remove(option)" matTooltip="{{option.item_tooltip_text}}">
        {{option.item_text}}
        <button (click)="selectedItemsChange.emit(selectedItems)" matChipRemove [attr.aria-label]="'remove ' + option.item_text">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-listbox>

    <div *ngIf="!selectedItems || selectedItems.length === 0">{{placeholderText}}</div>
  </ng-template>
</p-multiSelect>

