<h1 mat-dialog-title>{{'Confirm' | translate}}</h1>
<div mat-dialog-content>

  <span><strong>{{ 'Are you sure you want to resend' | translate }}</strong> #{{dicomJob?.id}}</span>
  <br>
  <br>
  <mat-slide-toggle *ngIf="isShowSelectOnlyResendErrors && !resendLevel" class="m-b-20" [(ngModel)]="isOnlyResendErrors">{{'Only resend errors' | translate}}</mat-slide-toggle>
  <br>
  <mat-slide-toggle *ngIf="dicomJob?.cType === 'c-move'" class="m-b-20" (change)="changeResendLevel($event)">{{'Change resend level' | translate}}</mat-slide-toggle>

  <div *ngIf="resendLevel">
    <p class="text-warning">{{'This will resend the entire study on one of the below levels' | translate}}</p>
    <mat-button-toggle-group [(ngModel)]="resendLevel" name="theme" aria-label="Theme selection">
      <mat-button-toggle [value]="'study'">{{'Study level' | translate}}</mat-button-toggle>
      <mat-button-toggle [value]="'series'">{{'Series level' | translate}}</mat-button-toggle>
      <mat-button-toggle [value]="'instance'">{{'Instance level' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>


</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
  <button mat-raised-button color={{data.okButtonColor}} [mat-dialog-close]="{isOnlyResendErrors: isOnlyResendErrors, resendLevel: resendLevel}" cdkFocusInitial>{{'Yes' | translate}}</button>
</div>
