import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {animate, state, style, transition, trigger} from '@angular/animations';


@Component({
    selector: 'app-pretty-details-dialog',
    templateUrl: 'html-dialog.component.html',
    styleUrls: ['html-dialog.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('*', style({ height: '*', visibility: 'visible' })),
            transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class HtmlDialogComponent {

    detail?: any;

    htmlString?: string;

    constructor(
        public dialogRef: MatDialogRef<HtmlDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {

        this.htmlString = this.ParseToHtml(data.detail);

    }

    private ParseToHtml(detail: any): string {

        let html = '<p>';

        html += detail.toString()
            .replace(/(?:\\.br\\)/g, '<br>')
            .replace(/(?:\\X0A\\\\X0D\\|\\X0A\\|\\X0D\\)/g, '<br>');

        html += '</p>';

        return html;

    }
}
