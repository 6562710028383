import {ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {$UserHaveFunction, FunctionImportDicom, User} from '../../../../shared/models/user.model';
import {ApiService} from '../../../../shared/services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../../shared/services/user.service';
import {ActivatedRoute} from '@angular/router';
import {
    ExportJobDto, ExportJobStepDto,
    ExportJobStepMessageDto,
    ResponseExportDownload,
    ResponseExportJobStepMessages
} from '../../../../shared/models/exports.model';
import {interval, Subscription, timeout} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {filter, switchMap, take} from 'rxjs/operators';
import {
    PrettyDetailsDialogComponent
} from '../../../../shared/dialog/pretty-details-dialog/pretty-details-dialog.component';
import {HelperService} from '../../../../shared/services/helper.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
    selector: 'app-exports-download',
    templateUrl: './exports-download.component.html',
    styleUrls: ['./exports-download.component.scss']
})
export class ExportsDownloadComponent implements OnDestroy, OnInit {

    authedUser: User | undefined;

    disposable?: Subscription;

    exportJob?: ExportJobDto;

    exportJobStepMessages?: ExportJobStepMessageDto[];

    accessToken?: string;

    showIncludedItems = false;

    progressTotalFiles = 0;
    progressDoneFiles = 0;

    private timerSub?: Subscription;

    private routeSub?: Subscription;

    constructor(private _cdr: ChangeDetectorRef,
                private _renderer: Renderer2,
                private _apiService: ApiService,
                private _dialog: MatDialog,
                private _helperService: HelperService,
                private _translate: TranslateService,
                private _userService: UserService,
                private _toastService: ToastService,
                private _route: ActivatedRoute) {
    }

    ngOnInit(): void {

        this.routeSub = this._route.queryParams.subscribe(params => {
            console.log(params); // log the entire params object

            this.accessToken = params.key;

            this.loadDownloadData();

        });


        this.disposable = this._userService.listenToAuthedUser().subscribe(x => {
            this.authedUser = x;

            if(!this.authedUser?.userId)
            {return;}

        });

    }

    getJobStepMessages(item: ExportJobStepDto): ExportJobStepMessageDto[] | undefined {
        return this.exportJobStepMessages?.filter(c => c.exportJobStepId === item.id);
    }

    showDetails(item: ExportJobStepDto): void {

        const jobSteps = this.getJobStepMessages(item);

        const dialogRef = this._dialog.open(PrettyDetailsDialogComponent, {
            width: '80%',
            enterAnimationDuration: '100ms',
            exitAnimationDuration: '100ms',
            data: {
                detail: jobSteps
            }
        });

        /*
    const dialogRef = this._dialog.open(PrettyDetailsDialogComponent, {
      width: '80%',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        detail: item,
      }
    });
     */

        dialogRef.afterClosed().subscribe(selectedExaminationsObj => {

            if (!selectedExaminationsObj)
            {return;}

        });

    }

    private loadDownloadData() {

        this._cdr.detectChanges();

        const uri = '/export/single';

        this._apiService.getWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? '').subscribe({
            next: (res) => {
                console.log('res.data',  res.data);

                this.exportJob = res.data;

                if(this.exportJob.status === 'processing')
                {this.pollForDone();}

            },
            error: (error: any) => {
                console.log('error examinations', error);
            }});

        const uri2 = '/export/single/messages';

        this._apiService.getWithCustomAccessToken<ResponseExportJobStepMessages>(uri2, this.accessToken ?? '').subscribe({
            next: (res2) => {
                console.log('res.data',  res2.data);
                this.exportJobStepMessages = res2.data.messages;

            }});



    }

    ngOnDestroy(): void {
        this.disposable?.unsubscribe();
        this.routeSub?.unsubscribe();
        this.timerSub?.unsubscribe();
    }


    prepareExport(): void {

        const uri = '/export/prepare';

        if(!this.exportJob)
        {return;}

        this.exportJob.status = 'processing';

        this._apiService.putWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? '').subscribe({
            next: (res) => {
                console.log('res.data', res.data);

                this.exportJob = res.data;

                this.pollForDone();

            },
            error: (error: any) => {

                if(this.exportJob)
                {this.exportJob.status = 'fail';}

                console.log('error examinations', error);
            }});

    }


    pollForDone(): void {

        const uri = '/export/single';
        const uri2 = '/export/single/messages';

        this.handleProgressBar();

        if(this.timerSub)
        {return;}

        // Create an Observable that emits every 5 seconds
        this.timerSub = interval(5000).pipe(

            // Since the httpClient returns an Observable, wrap it with a switchMap
            switchMap(() => this._apiService.getWithCustomAccessToken<ExportJobDto>(uri, this.accessToken ?? '')),

            // Filter only the successful http responses
            filter((data) => {

                console.log('poll res.data', data.data);

                this.exportJob = data.data;

                this._apiService.getWithCustomAccessToken<ResponseExportJobStepMessages>(uri2, this.accessToken ?? '').subscribe({
                    next: (res2) => {
                        console.log('res.data',  res2.data);
                        this.exportJobStepMessages = res2.data.messages;
                    }});

                this.handleProgressBar();

                return data.data.status === 'success' || data.data.status === 'fail' || data.data.status === 'new' || data.data.status === 'deleted';
            }),
            // Emit only the first value emitted by the source
            take(1),

            // Time out after 1 hour
            timeout(3600000),
        ).subscribe({
            next: (res) => {
                console.log('pollForDone res.data', res.data);

                this.exportJob = res.data;

            },
            error: (error: any) => {
                console.log('error examinations', error);
            }});

    }

    download(fileId?: number): void {

        const uri = '/export/download?exportFileId='+fileId;

        this._apiService.getWithCustomAccessToken<ResponseExportDownload>(uri, this.accessToken ?? '').subscribe({
            next: (res) => {
                console.log('res.data', res.data);

                const link = this._renderer.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', `${ environment.apiBaseUrl}/export/download/file?oneTimeKey=${res.data.oneTimeDownloadKey}`);
                link.setAttribute('download', `${ res.data.fileName}`);
                link.click();
                link.remove();

                setTimeout(() => {
                    this.loadDownloadData();
                }, 1000);

            },
            error: (error: any) => {
                console.log('error examinations', error);
            }});

    }

    getSpinnerColor(): 'accent' | 'primary' | 'warn' {

        if(this.exportJob?.status === 'fail')
        {return 'warn';}

        if(this.exportJob?.status === 'processing')
        {return 'primary';}

        return 'accent';
    }

    getSpinnerMode(): 'determinate' | 'indeterminate' {

        if(this.exportJob?.status === 'processing')
        {return 'indeterminate';}

        return 'determinate';
    }

    getSpinnerValue(): number {
        if(this.exportJob?.status === 'new')
        {return 50;}

        return 100;
    }
    getFormattedFileSize(bytes?: number): string {
        return this._helperService.getFormattedFileSize(bytes);
    }

    dateHaveNotPassedToday(startTime: Date): boolean {
        return new Date(startTime) >= new Date(Date.now());
    }

    copyLinkToClipboard(): void {

        navigator.clipboard.writeText(window.location.href);

        this._toastService.add({message: 'The link was copied to clipboard', type: 'success'});

    }

    protected readonly FunctionImportDicom = FunctionImportDicom;
    protected readonly $UserHaveFunction = $UserHaveFunction;


    getLastPath(queryMeta?: string): string {

        if(!queryMeta)
        {return '';}

        let parts = queryMeta.split('/');

        if(parts.length === 1) {
            parts = parts[parts.length-1].split('\\');
        }

        return parts[parts.length-1];

    }

    private handleProgressBar() {
        if(this.exportJob?.status === 'processing') {

            this.progressTotalFiles = 0;
            this.progressDoneFiles = 0;
            for (const step of this.exportJob.exportJobSteps ?? []) {

                const exportMessages = this.getJobStepMessages(step)?.map(c => c.message).join(' ');

                // console.log('exportMessages', exportMessages);

                const countsTotal = this._helperService.getFromBetween.get(
                    'TotalFilesStart',
                    'TotalFilesEnd',
                    exportMessages);

                const countsDone = this._helperService.getFromBetween.get(
                    'DoneFilesStart',
                    'DoneFilesEnd',
                    exportMessages);

                if(countsTotal.length > 0) {
                    const first = countsTotal[0];
                    this.progressTotalFiles += Number(first?.trim() ?? '0');
                }

                if(countsDone.length > 0) {
                    const last = countsDone[countsDone.length-1];
                    this.progressDoneFiles += Number(last?.trim() ?? '0');
                }

            }

        }
    }
}
