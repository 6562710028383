import {Component, OnDestroy} from '@angular/core';
import {User} from '../../../shared/models/user.model';
import {UserService} from '../../../shared/services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy{
    stacked = false;
    authedUser: User | undefined;
    isLoading = false;
    disposable: any;
    showArea = 'none';
    constructor(private _userService: UserService, private _translate: TranslateService, private _route: ActivatedRoute) {}

    ngOnInit() {

        this._route.queryParams.subscribe(params => {
            console.log('params Home', params);
        });

        this.isLoading = true;
        this.disposable = this._userService.listenToAuthedUser().subscribe(x => {

            this.authedUser = x;


            if(x)
            {this.isLoading = false;}

        });


    }

    ngOnDestroy() {
        this.disposable.unsubscribe();
    }

    GetUserData(): User {

        const user: User = JSON.parse(JSON.stringify(this.authedUser));
        user.functions = undefined;
        user.archives = undefined;
        user.roles = undefined;
        user.systems = undefined;

        return user;

    }
}
