<mat-spinner class="table-spinner" *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="!isLoading">
  <div *ngIf="checklistSelection.selected.length === 0" id="sender_recipient_title_container">
    <strong>{{ 'Choose Sender and Recipient' | translate }}</strong>
  </div>
  <div class="justify-content-horizontal-between" *ngIf="checklistSelection.selected.length > 0">
    <div>
      <div *ngIf="selectedDestination"><b>{{ 'Chosen Sender' | translate }}:</b> {{selectedDestination.name}}</div>
      <div *ngIf="selectedParent"><b>{{ 'Chosen Recipient' | translate }}:</b> {{selectedParent.item.name}}</div>
    </div>
    <button id="edit_destination_selection_button" mat-icon-button (click)="unHideTree()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <mat-tree #treeSelector [dataSource]="dataSource" [treeControl]="treeControl" [ngClass]="{'m-t-10': checklistSelection.selected.length > 0}" *ngIf="!hideTree">
    <mat-tree-node class="checklist-tree-node" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <div #ref
        matTooltip="{{isOverflowing(ref, 'checkbox') ? node.item.name : ''}}"
        matTooltipPosition="above">
        <mat-checkbox class="checklist-leaf-node" color="primary"
                      [checked]="checklistSelection.isSelected(node)"
                      (change)="todoLeafItemSelectionToggle(node, $event.checked)"
                      [disabled]="node.item.level < 2"
                      >
                      <span class="tree-node-text-label">{{ node.item.name }}</span>
        </mat-checkbox>
      </div>
    </mat-tree-node>

    <mat-tree-node class="parent-tree-node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button #ref *ngIf="node.level >= 0" (click)="parentNodeClicked(node)" mat-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.item.name"
              matTooltip="{{isOverflowing(ref, 'button') ? node.item.name : '' ? node.item.name : ''}}"
              matTooltipPosition="above"
              >
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>

        <ng-container *ngIf="node.level === 0">
          <span class="tree-top-node-text tree-node-text-label">
            {{ node.item.name}}
          </span>
        </ng-container>

        <ng-container *ngIf="node.level > 0">
          <ng-container [ngSwitch]="checklistSelection.isSelected(node)">
            <mat-checkbox *ngSwitchCase="true"
                          [checked]="checklistSelection.isSelected(node)"
                          [disabled]="true"
                          color="primary"
                          [indeterminate]="descendantsPartiallySelected(node)"
                          (change)="todoItemSelectionToggle(node)"
                          class="w-auto">
                          <span class="tree-node-text-label">{{ node.item.name }}</span>
            </mat-checkbox>
            <span class="tree-top-node-text tree-node-text-label" *ngSwitchCase="false">
              {{ node.item.name }}
            </span>
          </ng-container>
        </ng-container>
      </button>
    </mat-tree-node>
  </mat-tree>
</ng-container>
