import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {HelperService} from '../../services/helper.service';

@Component({
    selector: 'app-cgi-mat-paginator',
    templateUrl: './cgi-mat-paginator.component.html',
    styleUrls: [],
})
export class CgiMatPaginatorComponent extends MatPaginator {

    @Input() defaultPageSizeOptions: number[] = [10, 20, 50, 100];

    @Input() currentPageSize = 0;

    paginatorLength = 0;

    paginatorPageIndex = 0;

    @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();


    constructor(
        private _helperService: HelperService,
        public _cdr: ChangeDetectorRef,
        public intl: MatPaginatorIntl,
    ) {

        super(intl, _cdr);

        this._helperService.sessionPageSize$.subscribe(x => {

            if(this.length === 0) {
                this.currentPageSize = x;
                this.pageSize = x;
            }

        });

    }



    changePageSize(event: PageEvent) {
        this.currentPageSize = event.pageSize;
        this.pageSize = event.pageSize;

        this.paginatorPageIndex = event.pageIndex ?? 0;
        this.pageIndex = this.paginatorPageIndex;

        this._helperService.sessionPageSizeSubject$.next(event.pageSize);
        this.pageChange.emit(event);

    }

    setLengthAndIndex(pageIndex?: number, length?: number, overridePageSize?: number): void {

        // this.length = undefined;
        this.paginatorLength = length ?? 0;
        this.length = this.paginatorLength;

        this.paginatorPageIndex = pageIndex ?? 0;
        this.pageIndex = this.paginatorPageIndex;

        if(overridePageSize) {
            this.currentPageSize = overridePageSize ?? 0;
            this.pageSize = this.currentPageSize;
        }

    }
}
